import { Box } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { usePedigreeLayout } from 'common/features/pedigree/common/hooks/usePedigreeLayout';
import usePedigreeNodeRelationshipLabels from 'common/features/pedigree/common/hooks/usePedigreeNodeRelationshipLabels';
import { PedigreeNode } from 'common/features/pedigree/common/types';
import PedigreeEdges from 'common/features/pedigree/common/views/pedigreeRenderer/PedigreeEdges';
import { CANCER_COLORS_MAP } from 'common/features/pedigree/pedigreeProfessional/utils/generateDiseaseToColorMap';
import { LoadingBackdrop } from 'components/common';
import ResponsiveStage from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeRenderer/controllers/ResponsiveStage';
import usePedigreeResponsiveActions from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeRenderer/hooks/usePedigreeResponsiveActions';
import PedigreeControlButtonsView from 'myfamilytree/features/myFamilyTreeSubmission/pedigreeRenderer/views/PedigreeControlButtonsView';
import { useMemo, useRef, useState } from 'react';
import { Group } from 'react-konva';
import { useQuery } from 'react-query';
import { usePedigreePrintExport } from '../../../../../common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import NodeInformationDrawer from './controllers/NodeInformationDrawer';
import { usePedigreeFilterProps } from './hooks/usePedigreeFilrerView';
import PedigreeFiltersView from './views/PedigreeFiltersView';
import { PedigreeNodeView } from './views/PedigreeNodeView';

const NODE_SIZE = {
  width: 50,
  height: 50,
};

interface PedigreeOverviewProps {
  pedigreeCaseId: string;
  patientId: string;
}

export function PedigreeOverview({
  pedigreeCaseId,
  patientId,
}: PedigreeOverviewProps) {
  const stageRef = useRef(null);

  const service = useStaffPedigreeCaseService();

  const { data: pedigree, isLoading } = useQuery(['pedigree', patientId], () =>
    service.getPedigreeByPatientId(patientId),
  );

  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);

  const diagnoseToColorMap = useMemo(() => {
    if (!pedigree) return {};
    return generateDiseaseToColorMap(pedigree.nodes);
  }, [pedigree]);

  const { layout, layoutSize } = usePedigreeLayout({
    pedigreeNodes: pedigree?.nodes || [],
    pedigreeType: 'professionalReadonly',
  });

  const { getLocalizedLabelOf } = usePedigreeNodeRelationshipLabels(
    pedigree?.nodes || [],
  );

  const indexName = useMemo(() => {
    return pedigree?.nodes.find((node) => node.isIndex)?.name || '';
  }, [pedigree]);

  const selectedNodeRelationLabel = useMemo(() => {
    if (selectedNodeId !== null) {
      const label = getLocalizedLabelOf(selectedNodeId);
      if (label === 'index') {
        return indexName;
      }
      return label;
    }
    return '';
  }, [selectedNodeId, getLocalizedLabelOf, indexName]);

  const { handleZoomIn, handleZoomOut, handleZoomReset } =
    usePedigreeResponsiveActions({
      nodeSize: NODE_SIZE,
      stageRef,
    });

  const { onPedigreePrintClick, onPedigreeDownloadClick, dialog } =
    usePedigreePrintExport();

  const { visibleNodeProperties, filterViewProps } =
    usePedigreeFilterProps(diagnoseToColorMap);

  if (isLoading) return <LoadingBackdrop />;
  if (!pedigree) return null;

  return (
    <>
      {dialog}
      <Box display="flex" flexDirection="column" height="100vh">
        <PedigreeFiltersView
          {...filterViewProps}
          receivedAt={pedigree.receivedAt}
        />
        <PedigreeControlButtonsView
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          handleZoomReset={handleZoomReset}
          handleDownload={() => onPedigreeDownloadClick(pedigreeCaseId)}
          handlePrint={() => onPedigreePrintClick(pedigreeCaseId)}
        />
        <NodeInformationDrawer
          pedigreeCaseId={pedigreeCaseId}
          selectedNodeId={selectedNodeId}
          selectedNodeRelationLabel={selectedNodeRelationLabel}
          onClose={() => setSelectedNodeId(null)}
        />
        <ResponsiveStage stageRef={stageRef} layoutSize={layoutSize}>
          <PedigreeEdges
            links={layout?.links}
            strokeOptions={{
              color: 'black',
              width: 1,
            }}
          />

          {pedigree.nodes.map((pedigreeNode) => {
            const { x, y } = layout?.nodes[pedigreeNode.id]?.pos || {
              x: 0,
              y: 0,
            };

            return (
              <Group
                key={pedigreeNode.id}
                x={x}
                y={y}
                onClick={() => setSelectedNodeId(pedigreeNode.id)}
                onMouseEnter={(e) => {
                  const container = e.target.getStage()?.container();
                  if (container) container.style.cursor = 'pointer';
                }}
                onMouseLeave={(e) => {
                  const container = e.target.getStage()?.container();
                  if (container) container.style.cursor = 'default';
                }}
              >
                <PedigreeNodeView
                  isIndex={pedigreeNode.isIndex}
                  nodeWidth={NODE_SIZE.width}
                  sex={pedigreeNode.sex}
                  diagnoses={pedigreeNode.diagnoses}
                  name={pedigreeNode.name}
                  yearOfBirth={pedigreeNode.yearOfBirth}
                  ageAtDeath={pedigreeNode.ageAtDeath}
                  deceased={pedigreeNode.deceased}
                  diagnoseToColorMap={diagnoseToColorMap}
                  visibleNodeProperties={visibleNodeProperties}
                  isSelected={selectedNodeId === pedigreeNode.id}
                />
              </Group>
            );
          })}
        </ResponsiveStage>
      </Box>
    </>
  );
}

function generateDiseaseToColorMap(nodes: PedigreeNode[]) {
  const allDiseases = Array.from(
    new Set(
      nodes.reduce((acc, node) => {
        const nodesDiseases = node.diagnoses.map(
          (diagnosis) => diagnosis.diseaseName,
        );
        acc.push(...nodesDiseases);
        return acc;
      }, [] as string[]),
    ),
  );

  return allDiseases.reduce((acc, disease) => {
    acc[disease] = CANCER_COLORS_MAP[disease] || '#737679';
    return acc;
  }, {} as Record<string, string>);
}
