import { BaseService } from 'api/base-service';
import {
  serviceHookFactory,
  serviceProviderFactory,
} from 'myfamilytree/common/index';
import React from 'react';

type PatientDetails = {
  email?: string;
  phoneNumber?: string;
};

class StaffPatientService extends BaseService {
  updatePatientDetails = async (
    patientId: string,
    patientData: PatientDetails,
  ) => {
    return this.axiosInstance.put(
      `/staff/patients/${patientId}/patient-details`,
      patientData,
    );
  };
}

const StaffPatientServiceContext = React.createContext<{
  service: StaffPatientService | null;
}>({ service: null });

export const StaffPatientServiceProvider = serviceProviderFactory(
  StaffPatientService,
  StaffPatientServiceContext,
);

export const useStaffPatientService = serviceHookFactory(
  StaffPatientService,
  StaffPatientServiceContext,
);
