import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Button, Stack, Typography } from '@mui/material';
import { SectionBox } from 'components/common';
import { TitleWithIcon } from 'components/common/TitleWithIcon';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export const NoConsentSuggestionsPanel = () => {
  const { t } = useTranslation('consent');
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = () => {
    return navigate(`${location.pathname}/new-consent`);
  };

  return (
    <SectionBox sx={{ flexGrow: 1 }}>
      <Stack direction="column" spacing={3}>
        <TitleWithIcon
          icon={faInfoCircle}
          title={t('no-consent-suggestions-panel.no-relatives.title')}
        />
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'break-spaces',
          }}
        >
          {t('no-consent-suggestions-panel.no-relatives.description')}
        </Typography>
        <Button
          onClick={handleNavigate}
          variant="outlined"
          sx={{
            width: 'fit-content',
          }}
        >
          {t('no-consent-suggestions-panel.no-relatives.button-text')}
        </Button>
      </Stack>
    </SectionBox>
  );
};
