import { Stack } from '@mui/material';
import {
  QuestionsGroup,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import { useQuery } from 'react-query';
import QuestionnaireSectionView from '../views/QuestionnaireGroupView';

interface QuestionnaireOverviewControllerProps {
  pedigreeCaseId: string;
}

const QuestionnaireOverviewController = (
  props: QuestionnaireOverviewControllerProps,
) => {
  const service = useStaffPedigreeCaseService();
  const { pedigreeCaseId } = props;

  const { data, isLoading, error } = useQuery(
    ['questionnaire', pedigreeCaseId],
    () => service.getQuestionnaire(pedigreeCaseId || ''),
    { enabled: !!pedigreeCaseId },
  );

  if (isLoading) return null;

  if (error) return <div>Error...</div>;

  if (!data) return <div>No data available</div>;

  return (
    <Stack gap={3}>
      {data.groups.map((group: QuestionsGroup, index: number) => (
        <QuestionnaireSectionView
          key={index}
          groupTitle={group.title}
          defaultExpanded={index === 0}
          questions={group.questions}
        />
      ))}
    </Stack>
  );
};

export default QuestionnaireOverviewController;
