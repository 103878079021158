import { Button, Stack, Typography, useTheme } from '@mui/material';
import Dialog from 'components/common/Dialog';
import {
  Children,
  cloneElement,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { DynamicFormContext } from '../..';
import { GeneticTestHeader } from './GeneticTestHeader';

type GeneticTest = {
  id: string;
  pathogenicVariantsFound: 'yes' | 'no' | 'notSure';
  geneticTestVariants: {
    id: string;
    name: string;
    variants: {
      identifier: string;
      location: string;
      rsid: string;
    };
  }[];
  status: string;
  testLocation: string;
  testPanelGenes: string[];
  testType: string;
};

interface GeneticTestItemProps {
  title: string;
  children: ReactElement;
  geneticTest: GeneticTest;
  showAction: boolean;
  deleteGeneticTest: () => void;
}

export const GeneticTestItem = (props: GeneticTestItemProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['geneticTestForm']);
  const { updateFormState, setUpdateFormState } =
    useContext(DynamicFormContext);

  const { title, children, geneticTest, deleteGeneticTest } = props;

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [hasInitialState, setHasInitialState] = useState<boolean>(false);

  const handleDialogClose = () => {
    setIsOpenDeleteDialog(false);
  };

  const deleteDialogProps = useMemo(() => {
    return {
      open: isOpenDeleteDialog,
      title: t('dialog-delete.title'),
      content: t('dialog-delete.content'),
      actions: [
        <Button
          key="confirm-button-dialog"
          variant="contained"
          onClick={() => {
            deleteGeneticTest();
            handleDialogClose();
          }}
        >
          {t('dialog-delete.button.delete')}
        </Button>,
        <Button
          key="close-button-dialog"
          variant="outlined"
          onClick={handleDialogClose}
        >
          {t('dialog-delete.button.cancel')}
        </Button>,
      ],
    };
  }, [deleteGeneticTest, isOpenDeleteDialog, t]);

  const handleDeleteTest = () => {
    if (isDirty(geneticTest)) {
      return setIsOpenDeleteDialog(true);
    }
    return deleteGeneticTest();
  };

  const handleEditTest = () => {
    setIsReadOnly(false);
  };

  const status = children.props.formData?.status;

  useEffect(() => {
    if (!hasInitialState || !!updateFormState) {
      const isReadOnly = !!geneticTest.id ?? false;

      setIsReadOnly(isReadOnly);
      setHasInitialState(true);
      setUpdateFormState(false);
    }
  }, [geneticTest, hasInitialState, setUpdateFormState, updateFormState]);

  return (
    <>
      <Stack gap={0.5}>
        <GeneticTestHeader
          title={title}
          status={status}
          headerAction={isReadOnly ? 'edit' : 'delete'}
          onGeneticTestDelete={handleDeleteTest}
          onGeneticTestEdit={handleEditTest}
          showAction={props.showAction}
        />
        <Stack
          borderLeft={`1px solid ${theme.palette.common.opacity.darkBlue[25]}`}
          pl={2}
          pt={2}
        >
          {isReadOnly ? (
            <GeneticTestItemReadOnly
              children={children}
              isReadOnly={isReadOnly}
              pathogenicVariantsFound={geneticTest.pathogenicVariantsFound}
              hasGeneticTestVariants={
                geneticTest.geneticTestVariants?.length > 0
              }
            />
          ) : (
            children
          )}
        </Stack>
      </Stack>
      <Dialog {...deleteDialogProps} />
    </>
  );
};

interface GeneticTestItemReadOnlyProps {
  children: ReactElement;
  pathogenicVariantsFound: 'yes' | 'no' | 'notSure';
  hasGeneticTestVariants: boolean;
  isReadOnly: boolean;
}

const GeneticTestItemReadOnly = (props: GeneticTestItemReadOnlyProps) => {
  const theme = useTheme();
  const { t } = useTranslation(['geneticTestForm']);

  const {
    children,
    pathogenicVariantsFound,
    hasGeneticTestVariants,
    isReadOnly,
  } = props;

  const pathogenicVariantsTitle =
    children.props.uiSchema?.geneticTestVariants['ui:title'];

  const useLabelNoPathogenicVariantsFound = useMemo(() => {
    if (pathogenicVariantsFound === 'no') {
      return t('genetic-test-variants.empty');
    }
    if (pathogenicVariantsFound === 'notSure') {
      return t('genetic-test-variants.unknown');
    }
    return '';
  }, [pathogenicVariantsFound, t]);

  const noRenderKeys = useMemo(() => {
    return ['pathogenicVariantsFound'];
  }, []);

  const childrenReadOnlyMode = useMemo(() => {
    return Children.map(children, (child) => {
      return cloneElement(child, {
        readonly: isReadOnly,
        uiSchema: {
          ...child.props.uiSchema,
          ...noRenderKeys.reduce(
            (acc: Record<string, { 'ui:widget': 'hidden' }>, key) => {
              acc[key] = { 'ui:widget': 'hidden' };
              return acc;
            },
            {},
          ),
        },
      });
    });
  }, [children, isReadOnly, noRenderKeys]);

  const gap = useLabelNoPathogenicVariantsFound ? 3 : 1;
  const showInfoGeneticTestVariants =
    hasGeneticTestVariants || !!useLabelNoPathogenicVariantsFound;

  return (
    <Stack gap={gap}>
      {showInfoGeneticTestVariants && (
        <Stack gap={0.5}>
          <Typography
            variant="body1"
            color={theme.palette.common.opacity.darkBlue[70]}
          >
            {pathogenicVariantsTitle}
          </Typography>
          {useLabelNoPathogenicVariantsFound && (
            <Typography variant="body1">
              {useLabelNoPathogenicVariantsFound}
            </Typography>
          )}
        </Stack>
      )}
      {childrenReadOnlyMode}
    </Stack>
  );
};

function isNotEmpty(value: any): boolean {
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length > 0;
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).length > 0;
  }
  return value !== null && value !== undefined;
}

function isDirty(item: { [key: string]: any }): boolean {
  const result: { [key: string]: boolean } = {};

  for (const key in item) {
    if (item.hasOwnProperty(key)) {
      result[key] = isNotEmpty(item[key]);
    }
  }

  return Object.values(result).some((valor) => valor === true);
}
