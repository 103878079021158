import { Container, TablePagination } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { LoadingBackdrop } from 'components/common';
import useConsentActions from 'features/consentManagement/listPatients/hooks/useConsentActions';
import EmptyState from 'iPedigree/common/EmptyState';
import Search from 'iPedigree/common/Search';
import { ROUTES } from 'iPedigree/routes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import PanelHeader from './PanelHeader';
import ConsentsTable, { Consent } from './table/ConsentsTable';

const ConsentListPanel: React.FC = () => {
  const { t } = useTranslation('iPedigree');
  const navigate = useNavigate();
  const service = useConsentManagementService();
  const { getConsentActions } = useConsentActions();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const limit = 20;

  const {
    data: paginatedConsents,
    isLoading,
    error,
  } = useQuery(['consents', search, page, limit], () =>
    service.listConsents({
      search,
      limit,
      offset: page * limit,
    }),
  );

  const showNoSearchResults = Boolean(
    !isLoading && search && paginatedConsents?.items.length === 0,
  );
  const showNoConsents =
    !isLoading && paginatedConsents?.totalCount === 0 && !showNoSearchResults;
  const showTable = Boolean(
    !isLoading &&
      paginatedConsents?.totalCount &&
      paginatedConsents?.totalCount > 0,
  );
  const showPagination = Boolean(
    showTable &&
      paginatedConsents?.totalCount &&
      paginatedConsents?.totalCount > limit,
  );
  const showError = Boolean(error);

  const consents = useMemo((): Consent[] => {
    return (
      paginatedConsents?.items.map((consent) => ({
        ...consent,
        isNew: consent.new,
        patientUrl: `${ROUTES.patient}/${consent.patientId}/consents`,
        actions: getConsentActions({
          ...consent,
          consentId: consent.id,
          consentStatus: consent.status,
        }),
        date: consent.updatedAt,
        relative: consent.name,
        patient: consent.patientName,
      })) || []
    );
  }, [paginatedConsents, getConsentActions]);

  return (
    <Container maxWidth={false} disableGutters>
      {isLoading && <LoadingBackdrop />}
      <PanelHeader
        onAddNewPatientClick={() => {
          navigate(ROUTES.newPatient);
        }}
        title={t('page-dashboard.consent-list.title')}
        buttonText={t('page-dashboard.new-patient.buttonText')}
      />
      <Search
        searchValue={search}
        setSearchValue={setSearch}
        searchPlaceholder={t('search.placeholder-text')}
        searchLabel={t('search.search')}
      />
      {showNoConsents && (
        <EmptyState
          title={t('page-dashboard.consent-list.empty-state.no-consents.title')}
          message={t(
            'page-dashboard.consent-list.empty-state.no-consents.message',
          )}
        />
      )}
      {showNoSearchResults && (
        <EmptyState
          title={t(
            'page-dashboard.consent-list.empty-state.no-search-results.title',
            { searchQuery: search },
          )}
          message={t(
            'page-dashboard.consent-list.empty-state.no-search-results.message',
          )}
        />
      )}
      {showTable && <ConsentsTable consents={consents} />}
      {showPagination && (
        <TablePagination
          component="div"
          count={paginatedConsents?.totalCount || 0}
          page={page}
          onPageChange={(_, nextPage) => {
            setPage(nextPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[]}
        />
      )}
      {showError && (
        <EmptyState
          title={t('page-dashboard.consent-list.error.title')}
          message={t('page-dashboard.consent-list.error.message')}
        />
      )}
    </Container>
  );
};

export default ConsentListPanel;
