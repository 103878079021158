import { User } from 'api/user/user-service';
import { useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { AddNodeParams } from './common/hooks/addNode/types';
import { ChangeParentsNodeParams } from './common/hooks/updateNode/useUpdateNodeParents';
import useHandleNodeAction, {
  PedigreeNodeAction,
} from './common/hooks/useHandleNodeAction';
import { useNodeMenu } from './common/hooks/useNodeMenu';
import { getNodeSize } from './common/hooks/usePedigreeLayout';
import usePedigreeNodeRelationshipLabels from './common/hooks/usePedigreeNodeRelationshipLabels';
import usePedigreeResponsiveActions from './common/hooks/usePedigreeResponsiveActions';
import { PedigreeContext } from './common/pedigreeContext';
import { PedigreeService, Pedigree as PedigreeType } from './common/types';
import { DialogContentType } from './common/views/Dialog';
import { PedigreePatient } from './pedigreePatient';
import PedigreeProfessional from './pedigreeProfessional';

export type PedigreeRendererType =
  | 'patient'
  | 'professionalReadonly'
  | 'professionalEditable';

export type DrawerFormConfirmationData = {
  pedigreeNodeId: string;
} & (AddNodeParams | ChangeParentsNodeParams);

interface PedigreeProps {
  pedigreeType: PedigreeRendererType;
  user?: User;
  service: PedigreeService;
}

export type DrawerState = {
  type: 'parentSelection' | 'nodeForm' | 'nodeInformation' | 'twinSelection';
  action: PedigreeNodeAction;
  pedigreeNodeId: string;
  onClickConfirm?: (data: DrawerFormConfirmationData) => void;
};

function Pedigree(props: PedigreeProps) {
  const stageRef = useRef<any>(null);

  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [highlightedNodeIds, setHighlightedNodeIds] = useState<string[] | null>(
    null,
  );
  const [selectedNodeState, setSelectedNodeState] = useState<string[] | null>(
    null,
  );
  const [drawerState, setDrawerState] = useState<DrawerState | null>(null);
  const [dialogContent, setDialogContent] = useState<DialogContentType>({
    title: '',
    content: null,
    actions: [],
    open: false,
  });
  const [pedigree, setPedigree] = useState<PedigreeType | null>(null);

  const isProfessional =
    props.pedigreeType === 'professionalReadonly' ||
    props.pedigreeType === 'professionalEditable';

  const isEditMode =
    props.pedigreeType === 'professionalEditable' ||
    props.pedigreeType === 'patient';

  useQuery(['pedigree'], () => props.service.getPedigree(), {
    onSuccess: (data) => {
      setPedigree(data);
    },
  });

  const { getLocalizedLabelOf } = usePedigreeNodeRelationshipLabels(
    pedigree?.nodes || [],
  );

  const { handleNodeAction } = useHandleNodeAction({
    pedigree,
    setDialogContent,
    setSelectedNodeId,
    setDrawerState,
    setPedigree,
    service: props.service,
    pedigreeType: isProfessional ? 'professional' : 'patient',
    isEditMode,
  });

  const { openNodeMenu, closeNodeMenu, NodeMenu } = useNodeMenu({
    handleNodeAction,
    setSelectedNodeState,
    isEditMode: props.pedigreeType === 'professionalEditable',
  });

  const nodeSize = getNodeSize(props.pedigreeType);

  const { handleZoomIn, handleZoomOut, handleZoomReset } =
    usePedigreeResponsiveActions({
      nodeSize,
      stageRef,
    });

  const selectedNode = useMemo(() => {
    if (!selectedNodeId || !pedigree) return null;
    return pedigree.nodes.find((node) => node.id === selectedNodeId) || null;
  }, [selectedNodeId, pedigree]);

  if (!pedigree) return null;

  return (
    <PedigreeContext.Provider
      value={{
        pedigree,
        setPedigree,
        pedigreeType: props.pedigreeType,
        selectedNodeId,
        setSelectedNodeId,
        selectedNode,
        highlightedNodeIds,
        setHighlightedNodeIds,
        selectedNodeState,
        setSelectedNodeState,
        drawerState,
        drawerDefaultWidth: 464,
        setDrawerState,
        dialogContent,
        stageRef,
        getLocalizedLabelOf,
        setDialogContent,
        handleNodeAction,
        openNodeMenu,
        closeNodeMenu,
        handleZoomIn,
        handleZoomOut,
        handleZoomReset,
        NodeMenu,
        editMode: isEditMode,
        nodeSize,
        service: props.service,
        user: props.user,
      }}
    >
      {props.pedigreeType === 'patient' && <PedigreePatient />}
      {isProfessional && <PedigreeProfessional />}
    </PedigreeContext.Provider>
  );
}

export default Pedigree;
