import { StaffPedigreeCaseServiceProvider } from 'api/pedigreeCase/staff-pedigree-case-service';
import TopNav from 'common/layouts/TopNav';
import { withStaffAuthProvider } from 'features/auth/staff/StaffAuthProvider';
import { useStaffAuth } from 'myfamilytree/common/index';
import Dashboard from 'myfamilytree/pages/myFamilyTreeManagement/Dashboard';
import NewPatientPage from 'myfamilytree/pages/myFamilyTreeManagement/NewPatientPage';
import { PedigreeCasePage } from 'myfamilytree/pages/myFamilyTreeManagement/PedigreeCasePage';
import SendReminderPage from 'myfamilytree/pages/myFamilyTreeManagement/SendReminderPage';
import SendRequestPage from 'myfamilytree/pages/myFamilyTreeManagement/SendRequestPage';
import { Route, Routes } from 'react-router-dom';

function MyFamilyTreeManagementRouter() {
  const { accessToken, logout } = useStaffAuth();

  return (
    <StaffPedigreeCaseServiceProvider accessToken={accessToken} logout={logout}>
      <TopNav />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/new-patient" element={<NewPatientPage />} />
        <Route
          path="/patient/:patientId/send-request"
          element={<SendRequestPage />}
        />
        <Route
          path="/patient/:patientId/send-reminder"
          element={<SendReminderPage />}
        />
        <Route path="/patient/:patientId" element={<PedigreeCasePage />} />
      </Routes>
    </StaffPedigreeCaseServiceProvider>
  );
}

export default withStaffAuthProvider(MyFamilyTreeManagementRouter);
