import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: Palette['primary'];
  }
  interface CommonColors {
    neutral: {
      100: string;
      200: string;
      300: string;
      400: string;
      500: string;
      600: string;
    };
    brand: {
      blue: string;
      lightBlue: string;
      darkBlue: string;
      darkBlue70: string;
      purple: string;
      lightPurple: string;
      darkPurple: string;
      gray: string;
      darkGray: string;
      rose: string;
      lightRose: string;
      bankid: string;
      bankidHover: string;
      lightGreen: string;
      infoBackground: string;
    };
    hover: {
      blue: string;
      beige: string;
      lightPurple: string;
    };
    opacity: {
      darkBlue: {
        0o7: string;
        12: string;
        25: string;
        35: string;
        55: string;
        70: string;
      };
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 700,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

theme.palette.common.brand = {
  blue: '#0070f7',
  lightBlue: '#71D7FF',
  darkBlue: '#233749',
  darkBlue70: '#657380',
  purple: '#99AFE8',
  lightPurple: '#EBF1FB',
  darkPurple: '#440060',
  rose: '#D8B6B6',
  lightRose: '#F9EEE5',
  gray: '#737679',
  darkGray: '#4F4F4F',
  bankid: '#193E4F',
  bankidHover: '#0B2028',
  lightGreen: '#E6FBEA',
  infoBackground: '#E5F1FE',
};

theme.palette.primary = {
  main: theme.palette.common.brand.blue,
  light: '',
  dark: '#0046C3',
  contrastText: 'white',
};

theme.palette.tertiary = {
  main: theme.palette.common.brand.darkBlue,
  light: '',
  dark: '',
  contrastText: 'white',
};

// theme.palette.secondary = {
//   main: "#99AFE8",
//   light: "#EBF1FB",
//   dark: "#440060",
//   contrastText: "",
// };

theme.palette.error = {
  main: '#BA3A34',
  dark: '#851810',
  light: '#FCECEE',
  contrastText: '',
};

theme.palette.warning = {
  main: '#DE9138',
  dark: '#A86322',
  light: '#FAF3E3',
  contrastText: '',
};

theme.palette.text.primary = theme.palette.common.brand.darkBlue;
theme.palette.success.contrastText = theme.palette.text.primary;

theme.palette.info = {
  main: '#3789D3',
  dark: '#0046C3',
  light: '#EBF1FB',
  contrastText: '',
};

theme.palette.common.neutral = {
  100: '#FAFAFA',
  200: '#2337491F',
  300: '#23374940',
  400: '#23374959',
  500: '#2337498C',
  600: '#233749B2',
};

theme.palette.common.hover = {
  blue: '#0046C3',
  beige: '#FAE2CF',
  lightPurple: '#EBF1FB',
};

theme.palette.common.opacity = {
  darkBlue: {
    0o7: 'rgba(35, 55, 73, 0.07)',
    12: 'rgba(35, 55, 73, 0.12)',
    25: 'rgba(35, 55, 73, 0.25)',
    35: 'rgba(35, 55, 73, 0.35)',
    55: 'rgba(35, 55, 73, 0.55)',
    70: 'rgba(35, 55, 73, 0.7)',
  },
};

theme.palette.text.primary = theme.palette.common.brand.darkBlue;
theme.typography.caption.color = theme.palette.common.neutral[600];

theme.typography.body1 = {
  fontSize: 16,
  fontWeight: 300,
  color: theme.palette.text.primary,
  lineHeight: '22px',
};

theme.typography.body2 = {
  fontSize: 14,
  fontWeight: 300,
  color: theme.palette.text.primary,
  lineHeight: '20px',
};

theme.typography.h1 = {
  fontSize: 36,
  fontWeight: 600,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: 28,
  },
};

theme.typography.h2 = {
  fontSize: 28,
  fontWeight: 400,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: 24,
  },
};

theme.typography.h3 = {
  fontSize: 24,
  fontWeight: 600,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
};

theme.typography.h4 = {
  fontSize: 20,
  fontWeight: 400,
  color: theme.palette.text.primary,
  [theme.breakpoints.down('sm')]: {
    fontSize: 18,
  },
};

theme.typography.h5 = {
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.text.primary,
};

theme.typography.h6 = {
  fontSize: 14,
  fontWeight: 600,
  color: theme.palette.text.primary,
};

theme.components = {
  MuiInputBase: {
    styleOverrides: {
      root: {
        '& .Mui-error': {
          '& fieldset': {
            border: 'solid 1px',
            borderColor: theme.palette.error.main,
          },
        },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
          WebkitAppearance: 'none',
          margin: 0,
        },
        '& .MuiInputBase-sizeSmall': {
          input: {
            padding: '8px',
          },
        },
        '&.MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: theme.palette.grey[400],
          },
        },
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        fontWeight: 400,
        textTransform: 'none',
        '&.MuiButton-sizeSmall': {
          fontSize: 12,
        },
        '&.Mui-disabled.MuiButton-contained': {
          color: theme.palette.common.opacity.darkBlue[35],
          backgroundColor: theme.palette.common.opacity.darkBlue[12],
          borderColor: theme.palette.common.opacity.darkBlue[12],
        },
        '&.Mui-disabled.MuiButton-outlined': {
          color: theme.palette.common.opacity.darkBlue[35],
          borderColor: theme.palette.common.opacity.darkBlue[12],
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        // color: theme.palette.common.brand.darkBlue,
        '&:hover': {
          backgroundColor: theme.palette.common.hover.lightPurple,
        },
        '&.Mui-disabled': {
          color: theme.palette.common.opacity.darkBlue[35],
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: '0px',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        '.MuiFormLabel-asterisk': {
          color: theme.palette.error.main,
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontWeight: 300,
        fontSize: '16px',
        color: theme.palette.primary.main,
        '&.Mui-selected': {
          fontWeight: 600,
        },
        '&:hover': {
          backgroundColor: theme.palette.common.hover.lightPurple,
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '& .MuiDialog-paperWidthSm': {
          width: '100%',
          maxWidth: '544px',
        },
        '& .MuiDialogTitle-root': {
          fontSize: 20,
          fontWeight: 400,
          padding: '30px 32px 24px 32px',
        },
        '& .MuiDialogContent-root': {
          padding: '0 32px 20px 32px',
          fontWeight: 300,
        },
        '& .MuiDialogContentText-root': {
          color: theme.palette.common.brand.darkBlue,
        },
        '& .MuiDialogActions-root': {
          padding: '8px 32px 32px 32px',
          '& .MuiButton-outlined': {
            padding: '5px 24px',
            marginRight: 8,
          },
          '& .MuiButton-contained': {
            padding: '6px 24px',
          },
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '16px',
        '&.MuiTableCell-head': {
          fontWeight: 600,
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      option: {
        "&[aria-selected='true']": {
          backgroundColor: `${theme.palette.common.brand.blue} !important`,
          color: 'white',
        },
        "&[aria-selected='true']:hover": {
          backgroundColor: `${theme.palette.common.hover.blue} !important`,
        },
        '&:hover': {
          backgroundColor: `${theme.palette.common.hover.lightPurple} !important`,
        },
      },
      tag: {
        border: `1px solid ${theme.palette.common.neutral[500]}`,
        backgroundColor: 'white',
        '& .MuiChip-deleteIcon': {
          color: theme.palette.common.neutral[600],
          '&:hover': {
            color: theme.palette.common.hover.blue,
          },
        },
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        '&.rich-text-editor': {
          border: `1px solid ${theme.palette.common.neutral[500]}`,
          borderRadius: theme.spacing(0.5),
          '&:hover': {
            borderColor: theme.palette.primary.main,
          },
          '&.focused': {
            border: `2px solid ${theme.palette.primary.main}`,
          },
          'div.ProseMirror-focused': {
            outline: 'none',
          },
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        padding: '8px 16px',
        fontSize: 16,
        color: theme.palette.common.brand.darkBlue,
      },
      standardInfo: {
        backgroundColor: theme.palette.info.light,
      },
      standardWarning: {
        backgroundColor: theme.palette.warning.light,
      },
      standardError: {
        backgroundColor: theme.palette.error.light,
      },
    },
  },
  MuiSnackbarContent: {
    styleOverrides: {
      root: {
        borderRadius: 200,
        backgroundColor: theme.palette.common.brand.gray,
        padding: 0,
        minWidth: undefined,
      },
      message: {
        ...theme.typography.body2,
        color: 'white',
        padding: '8px 12px',
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: theme.palette.info.light,
        color: theme.palette.common.brand.darkBlue,
        fontSize: 12,
        maxWidth: 300,
        fontWeight: 400,
        padding: '4px 8px',
        '& .MuiTooltip-arrow': {
          color: theme.palette.info.light,
        },
      },
    },
  },
};
export default theme;
