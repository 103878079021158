import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CircularProgress,
  Container,
  ContainerProps,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useUserService } from 'api/user/user-service';
import ArkusLogo from 'assets/images/arkus-logo.svg';
import { useStaffAuth } from 'features/auth/staff/StaffAuthProvider';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { LanguageSelector } from './LanguageSelector';

interface TopNavProps {
  containerMaxWidth?: ContainerProps['maxWidth'];
  settingsPath?: string;
}

function TopNav({ containerMaxWidth, settingsPath }: TopNavProps) {
  const { logout } = useStaffAuth();

  const { t } = useTranslation(['common']);

  return (
    <Container sx={{ py: 2, pt: 1, pb: 1 }} maxWidth={containerMaxWidth}>
      <Stack direction="row" justifyContent="space-between">
        <img alt={'logo'} src={ArkusLogo} />
        <Stack direction="row" spacing={4} alignItems="center">
          <LanguageSelector />
          {!settingsPath ? (
            <Button variant="text" onClick={logout}>
              {t('button.sign-out')}
            </Button>
          ) : (
            <DropdownMenu settingsPath={settingsPath} />
          )}
        </Stack>
      </Stack>
    </Container>
  );
}

function DropdownMenu({ settingsPath }: { settingsPath: string }) {
  const service = useUserService();
  const { t } = useTranslation(['common']);
  const { logout } = useStaffAuth();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const { data: user, isLoading } = useQuery(['user'], service.getCurrentUser);

  const handleLogout = () => {
    logout();
    navigate('/');
    setOpen(false);
  };

  const handleNavigateToSettings = () => {
    navigate(settingsPath);
    setOpen(false);
  };

  if (isLoading) return <CircularProgress size={24} sx={{ mx: 4 }} />;

  return (
    <>
      <Typography
        variant="body1"
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {user?.name}
        <FontAwesomeIcon
          icon={faCaretDown}
          style={{
            marginLeft: '8px',
            transition: 'transform 0.3s ease',
            transform: open
              ? 'rotate(180deg) translateY(-2px)'
              : 'rotate(0deg) translateY(0)',
          }}
        />
      </Typography>
      <Menu
        open={open}
        anchorEl={anchorRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              minWidth: anchorRef.current?.offsetWidth,
            },
          },
        }}
      >
        <MenuItem onClick={handleNavigateToSettings} divider>
          {t('button.settings')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('button.sign-out')}</MenuItem>
      </Menu>
    </>
  );
}

export default TopNav;
