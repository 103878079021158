import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { DrawerState } from 'common/features/pedigree';
import { useNotificationContext } from 'common/layouts/notification/NotificationProvider';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  GenerateCreateNodeParams,
  generateCreateNodeParams,
} from '../../generate-create-node-params';
import {
  AddNewPedigreeNode,
  CreateNodeParams,
  Pedigree,
  PedigreeNodeSex,
} from '../../types';
import { DialogContentType } from '../../views/Dialog';
import { AddNodeAction, AddNodeParams } from './types';

export type HandleCreateNewNodeMember = {
  pedigreeNodeId: string;
} & (
  | {
      action: AddNodeAction;
    }
  | AddNodeParams
);

interface useAddNodeProps {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
  addNewPedigreeNode: AddNewPedigreeNode;
  setDrawerState: (info: DrawerState) => void;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  setPedigree: (Pedigree: Pedigree | null) => void;
}

const ADD_NODE_ACTION_TO_SEX_RELATION_MAP: Record<
  AddNodeAction,
  {
    relation: any;
    sex?: PedigreeNodeSex;
  }
> = {
  addParents: { relation: 'parents' },
  addPartner: { relation: 'partner' },
  addBrother: { relation: 'sibling', sex: PedigreeNodeSex.MALE },
  addSister: { relation: 'sibling', sex: PedigreeNodeSex.FEMALE },
  addSiblingOfUnknownSex: { relation: 'sibling', sex: PedigreeNodeSex.UNKNOWN },
  addSon: { relation: 'child', sex: PedigreeNodeSex.MALE },
  addDaughter: { relation: 'child', sex: PedigreeNodeSex.FEMALE },
  addChildOfUnknownSex: { relation: 'child', sex: PedigreeNodeSex.UNKNOWN },
  addHalfBrother: { relation: 'half-sibling', sex: PedigreeNodeSex.MALE },
  addHalfSister: { relation: 'half-sibling', sex: PedigreeNodeSex.FEMALE },
  addHalfSiblingOfUnknownSex: {
    relation: 'half-sibling',
    sex: PedigreeNodeSex.UNKNOWN,
  },
  addIdenticalTwins: { relation: 'identical-twins' },
  addFraternalTwins: { relation: 'fraternal-twins' },
};

export const useAddNode = (props: useAddNodeProps) => {
  const { t } = useTranslation(['pedigree', 'common']);
  const { setNotification } = useNotificationContext();

  const createMemberMutation = useMutation(
    (nodeParams: CreateNodeParams) =>
      props.addNewPedigreeNode(props.pedigree?.id || '', nodeParams),
    {
      onSuccess: (data) => {
        props.setPedigree(data);
        setNotification({
          message: t('node-member-snackbar-message.add.success'),
          type: 'toast',
        });
      },
      onError: ({ response }: AxiosError) => {
        if (response?.status === 409) {
          return handleActionError(response.data.reason);
        }
        return handleActionError(response?.data.message);
      },
    },
  );

  const createNewNodeMember = async (params: GenerateCreateNodeParams) => {
    try {
      const nodeParams = generateCreateNodeParams(params);
      return createMemberMutation.mutate(nodeParams);
    } catch (error) {
      return setNotification({
        message: t('common:message.error'),
        type: 'error',
      });
    }
  };

  const closeDialog = () => {
    props.setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const handleActionError = (errorMessage: NodeNotAddableReason) => {
    const { title, content } = getDialogContent(t, errorMessage);

    if (title && content) {
      props.setDialogContent({
        open: true,
        title: title,
        content: content,
        actions: [
          <Button
            key="close-delete-dialog"
            variant="contained"
            onClick={closeDialog}
          >
            {t('node-member-dialog.add.button.isNotAllow')}
          </Button>,
        ],
      });
    } else {
      return setNotification({
        message: t('common:message.error'),
        type: 'error',
      });
    }
  };

  const handleCreateNewNodeMember = (params: HandleCreateNewNodeMember) => {
    if (!props.pedigree) return;

    createNewNodeMember({
      relationshipType:
        ADD_NODE_ACTION_TO_SEX_RELATION_MAP[params.action].relation,
      sex: ADD_NODE_ACTION_TO_SEX_RELATION_MAP[params.action].sex,
      pedigree: props.pedigree,
      targetNodeId: params.pedigreeNodeId,
      ...params,
    });
  };

  return {
    handleCreateNewNodeMember,
  };
};

export type NodeNotAddableReason =
  | 'NODE_WITH_UNKNOWN_SEX_CAN_NOT_HAVE_PARTNER'
  | 'NODE_WITH_UNKNOWN_SEX_CAN_NOT_HAVE_CHILD'
  | 'Child already has both parents';

const getDialogContent = (t: TFunction, reason: NodeNotAddableReason) => {
  const CONTENT_MAP = {
    NODE_WITH_UNKNOWN_SEX_CAN_NOT_HAVE_PARTNER: {
      title: t(
        'node-member-dialog.add.partner-to-node-unknown-of-sex.error.title',
      ),
      content: t(
        'node-member-dialog.add.partner-to-node-unknown-of-sex.error.content',
      ),
    },
    NODE_WITH_UNKNOWN_SEX_CAN_NOT_HAVE_CHILD: {
      title: t(
        'node-member-dialog.add.child-to-parent-unknown-of-sex.error.title',
      ),
      content: t(
        'node-member-dialog.add.child-to-parent-unknown-of-sex.error.content',
      ),
    },
    'Child already has both parents': {
      title: t('node-member-dialog.add.has-parents.error.title'),
      content: t(`node-member-dialog.add.has-parents.error.content`),
    },
  };
  return CONTENT_MAP[reason];
};
