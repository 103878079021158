import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { NewPatientFormData } from 'common/features/pedigreeCase/newPatient/types';
import { LoadingBackdrop } from 'components/common';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import NewPatientFormController from '../../../common/features/pedigreeCase/newPatient/controllers/NewPatientFormController';

function NewPatientPage() {
  const [newPatientData, setNewPatientData] = useState<
    NewPatientFormData | undefined
  >(undefined);
  const service = useStaffPedigreeCaseService();
  const navigate = useNavigate();
  let location = useLocation();

  const createNewPedigreeCaseMutation = useMutation(
    service.createNewPedigreeCaseRequest,
    {
      onSuccess: (data) => {
        navigate(`/patient/${data.patientId}/send-request`, {
          state: location.state,
        });
      },
    },
  );

  const handleContinueClick = (data: NewPatientFormData) => {
    setNewPatientData(data);
    if (!data) {
      return;
    }
    createNewPedigreeCaseMutation.mutate(data);
  };

  if (createNewPedigreeCaseMutation.isLoading) {
    return <LoadingBackdrop />;
  }

  return (
    <NewPatientFormController
      onContinueClick={handleContinueClick}
      onCancel={() =>
        navigate({
          pathname: '/',
          search: createSearchParams({
            tab: location.state,
          }).toString(),
        })
      }
      initialData={newPatientData}
      service={service}
    />
  );
}

export default NewPatientPage;
