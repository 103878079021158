import SendReminder from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendReminder';
import { useParams } from 'react-router-dom';

const SendReminderPage = () => {
  const { patientId } = useParams();

  if (!patientId) {
    throw new Error('Patient id is undefined');
  }

  return <SendReminder patientId={patientId} />;
};

export default SendReminderPage;
