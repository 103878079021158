import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { usePedigreePrintExport } from 'common/features/pedigreeCase/pedigreePrintExport/hooks/usePedigreePrintExport';
import { LoadingBackdrop } from 'components/common';
import EmptyState from 'iPedigree/common/EmptyState';
import Search from 'iPedigree/common/Search';
import { ROUTES } from 'iPedigree/routes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import DateView from '../../common/DateView';
import PanelHeader from './PanelHeader';
import ActionButtons from './table/ActionButtons';
import NewTagView from './table/NewTagView';
import PedigreeStatusView from './table/PedigreeStatusView';
import PersonalNumberView from './table/PersonalNumberView';

function PedigreeListPanel() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const limit = 20;
  const handleAddNewPatientClick = () => {
    navigate(ROUTES.newPatient);
  };

  const service = useStaffPedigreeCaseService();
  const { t } = useTranslation('iPedigree');
  const theme = useTheme();

  const { data: paginatedIPedigreeCases, isLoading } = useQuery(
    ['ipedigree-cases', search, page, limit],
    () =>
      service.getIpedigreeCases({
        searchQuery: search,
        limit: `${limit}`,
        offset: `${page * limit}`,
      }),
  );

  const { onPedigreePrintClick, onPedigreeDownloadClick, dialog } =
    usePedigreePrintExport();

  const handleOnViewClick = (patientId: string) => {
    navigate(`${ROUTES.patient}/${patientId}/pedigree`);
  };

  const handleOnReminderClick = (patientId: string) => {
    navigate(`${ROUTES.patient}/${patientId}/pedigree/send-reminder`);
  };

  const pedigreeList = paginatedIPedigreeCases?.items;

  const totalNumberOfRows = paginatedIPedigreeCases?.count || 0;

  const numberOfRows = useMemo(() => {
    return pedigreeList?.length || 0;
  }, [pedigreeList]);

  const headings = [
    '',
    t('page-dashboard.table.heading.name'),
    t('page-dashboard.table.heading.personalName'),
    t('page-dashboard.table.heading.status'),
    t('page-dashboard.table.heading.date'),
    t('page-dashboard.table.heading.familyId'),
    t('page-dashboard.table.heading.actions'),
  ];

  const showSearch =
    (pedigreeList && pedigreeList.length > 0) || search.length > 0;

  const emptyStateText = {
    title:
      search.length > 0
        ? t('page-dashboard.table.no-results.title', {
            searchQuery: search,
          })
        : t('page-dashboard.table.patient-empty-state.title'),
    message:
      search.length > 0
        ? t('page-dashboard.table.no-results.description')
        : t('page-dashboard.table.patient-empty-state.description'),
  };

  return (
    <>
      <Container maxWidth={false} disableGutters>
        {isLoading && <LoadingBackdrop />}
        <PanelHeader
          onAddNewPatientClick={handleAddNewPatientClick}
          title={t('page-dashboard.pedigree-list.title')}
          buttonText={t('page-dashboard.new-patient.buttonText')}
        />
        {showSearch && (
          <Search
            searchValue={search}
            setSearchValue={setSearch}
            searchPlaceholder={t('search.placeholder-text')}
            searchLabel={t('search.search')}
          />
        )}

        {numberOfRows === 0 ? (
          <EmptyState
            title={emptyStateText.title}
            message={emptyStateText.message}
          />
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {headings.map((heading) => (
                      <TableCell align="left" key={heading}>
                        {heading}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pedigreeList?.map((pedigree) => (
                    <TableRow key={pedigree.patientId}>
                      <TableCell sx={{ p: 0, pl: 2, width: 65 }}>
                        {pedigree.new && <NewTagView />}
                      </TableCell>
                      <TableCell>
                        <Link
                          to={`${ROUTES.patient}/${pedigree.patientId}`}
                          style={{
                            textDecoration: 'none',
                            color: theme.palette.common.brand.blue,
                          }}
                        >
                          {pedigree.patientName}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <PersonalNumberView
                          personalNumber={pedigree.patientPersonalNumber}
                        />
                      </TableCell>
                      <TableCell>
                        <PedigreeStatusView
                          pedigreeStatus={pedigree.pedigreeStatus}
                        />
                      </TableCell>
                      <TableCell>
                        <DateView isoDate={pedigree.pedigreeStatusDate} />
                      </TableCell>
                      <TableCell>{pedigree.familyId}</TableCell>
                      <TableCell sx={{ padding: 0.5 }}>
                        {pedigree.pedigreeStatus === 'requested' && (
                          <ActionButtons
                            onReminderClick={() =>
                              handleOnReminderClick(pedigree.patientId)
                            }
                          />
                        )}
                        {pedigree.pedigreeStatus === 'received' && (
                          <ActionButtons
                            onViewClick={() =>
                              handleOnViewClick(pedigree.patientId)
                            }
                          />
                        )}
                        {pedigree.pedigreeStatus === 'created' && (
                          <ActionButtons
                            onDownloadClick={() =>
                              onPedigreeDownloadClick(pedigree.pedigreeCaseId)
                            }
                            onPrintClick={() =>
                              onPedigreePrintClick(pedigree.pedigreeCaseId)
                            }
                            onViewClick={() =>
                              handleOnViewClick(pedigree.patientId)
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalNumberOfRows}
              page={page}
              onPageChange={(_, nextPage) => {
                setPage(nextPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[]}
            />
          </>
        )}
      </Container>
      {dialog}
    </>
  );
}

export default PedigreeListPanel;
