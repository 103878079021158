import { Container } from '@mui/material';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { useStaffPatientService } from 'api/patient/staff-patient-service';
import { useNotificationContext } from 'common/layouts/notification/NotificationProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { PatientData } from 'iPedigree/features/patient/common/PatientForm';
import ReminderForm, {
  ReminderData,
} from 'iPedigree/features/patient/consent/consentReminder/ReminderForm';
import { ConsentRequestReview } from 'iPedigree/features/patient/consent/ConsentRequestReview';
import ConsentSentConfirmation from 'iPedigree/features/patient/consent/ConsentSentConfirmation';
import { ROUTES } from 'iPedigree/routes';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

const SendConsentReminderPage: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const consentService = useConsentManagementService();
  const patientService = useStaffPatientService();

  const navigate = useNavigate();
  const { t } = useTranslation('consent');
  const { setNotification } = useNotificationContext();

  const [pageState, setPageState] = useState<
    'reminder-form' | 'reminder-preview' | 'sent-confirmation'
  >('reminder-form');
  const [reminderData, setReminderData] = useState<ReminderData | null>(null);

  const { data: consentRequest, isLoading: isConsentRequestLoading } = useQuery(
    ['consentRequest', patientId],
    () => consentService.getConsentRequestByPatientId(patientId || ''),
    {
      enabled: !!patientId,
    },
  );

  const setShowSendReminderError = useCallback(() => {
    setNotification({
      message: t('consent.reminder-form.error-message'),
      type: 'error',
    });
  }, [setNotification, t]);

  const sendReminderMutation = useMutation({
    mutationFn: async (params: {
      consentRequestId: string;
      patientId: string;
      data: ReminderData;
    }) => {
      await patientService.updatePatientDetails(params.patientId, {
        phoneNumber: params.data.patientContactDetails.phoneNumber,
        email: params.data.patientContactDetails.email,
      });
      const consentIds = params.data.relatives.map((relative) => relative.id);

      return consentService.sendReminder(params.consentRequestId, consentIds);
    },
    onSuccess: () => {
      setPageState('sent-confirmation');
    },
    onError: () => {
      setShowSendReminderError();
    },
  });

  const pendingConsents = useMemo(() => {
    if (!consentRequest) {
      return [];
    }
    return consentRequest.relatives.filter(
      (relative) => relative.consentStatus === 'pending',
    );
  }, [consentRequest]);

  const hasPendingConsents = useMemo(() => {
    return pendingConsents.length > 0;
  }, [pendingConsents]);

  const handleFormSubmit = useCallback(
    (data: ReminderData) => {
      setReminderData(data);
      setPageState('reminder-preview');
    },
    [setReminderData, setPageState],
  );

  const handleSendReminder = useCallback(() => {
    const consentRequestId = consentRequest?.id;
    if (!consentRequestId || !patientId || !reminderData) {
      setShowSendReminderError();
      return;
    }
    sendReminderMutation.mutate({
      consentRequestId,
      patientId,
      data: reminderData,
    });
  }, [
    consentRequest?.id,
    patientId,
    reminderData,
    sendReminderMutation,
    setShowSendReminderError,
  ]);

  const initialPatientData = useMemo<PatientData>(() => {
    const patientData = {
      phoneNumber: consentRequest?.patientData?.phoneNumber || '',
      email: consentRequest?.patientData?.email || '',
      personalNumber: consentRequest?.patientData?.personalNumber || '',
      name: consentRequest?.patientData?.name || '',
    };
    if (reminderData) {
      return {
        ...patientData,
        phoneNumber: reminderData.patientContactDetails.phoneNumber,
        email: reminderData.patientContactDetails.email,
      };
    }
    return patientData;
  }, [consentRequest, reminderData]);

  if (isConsentRequestLoading) {
    return <LoadingBackdrop />;
  }

  if (!consentRequest || !hasPendingConsents) {
    return <Navigate to={`${ROUTES.patient}/${patientId}/consents`} />;
  }

  if (pageState === 'reminder-form') {
    return (
      <Container sx={{ py: 3 }}>
        <ReminderForm
          onSubmit={handleFormSubmit}
          onCancel={() => navigate(-1)}
          initialPatientData={initialPatientData}
          relativesWithPendingConsents={pendingConsents}
          consentFor={consentRequest.consentFor}
        />
      </Container>
    );
  }

  if (!reminderData) {
    return <div>No reminder data</div>;
  }

  if (pageState === 'reminder-preview') {
    return (
      <Container sx={{ py: 3 }}>
        <ConsentRequestReview
          patientName={consentRequest.patientData.name}
          patientPersonalNumber={consentRequest.patientData.personalNumber}
          relatives={reminderData.relatives.map((relative) => ({
            name: relative.name,
            relationshipToPatient: relative.relationship,
            personalNumber: consentRequest.patientData.personalNumber,
          }))}
          onBackButtonClick={() => navigate(-1)}
          onContinueClick={handleSendReminder}
          onContinueClickLoading={sendReminderMutation.isLoading}
          reminder
        />
      </Container>
    );
  }

  if (pageState === 'sent-confirmation') {
    return (
      <Container sx={{ py: 3 }}>
        <ConsentSentConfirmation
          patientName={consentRequest.patientData.name}
          relatives={reminderData.relatives.map((relative) => ({
            name: relative.name,
            relationshipToPatient: relative.relationship,
            personalNumber: consentRequest.patientData.personalNumber,
          }))}
          localizationNamespace="reminder-form"
        />
      </Container>
    );
  }
};

export default SendConsentReminderPage;
