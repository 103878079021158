import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import EmptyState from 'iPedigree/common/EmptyState';
import Search from 'iPedigree/common/Search';
import { ROUTES } from 'iPedigree/routes';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import PanelHeader from './PanelHeader';
import PedigreeStatusView from './table/PedigreeStatusView';
import PersonalNumberView from './table/PersonalNumberView';

function PatientListPanel() {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const limit = 20;

  const handleAddNewPatientClick = () => {
    navigate(ROUTES.newPatient);
  };

  const service = useStaffPedigreeCaseService();
  const { t } = useTranslation('iPedigree');

  const theme = useTheme();

  const { data: paginatedIPedigreeCases, isLoading } = useQuery(
    ['ipedigree-cases', search, page, limit],
    () =>
      service.getIpedigreeCases({
        searchQuery: search,
        limit: `${limit}`,
        offset: `${page * limit}`,
      }),
  );

  const patientData = paginatedIPedigreeCases?.items;

  const totalNumberOfRows = paginatedIPedigreeCases?.count || 0;

  const numberOfRows = useMemo(() => {
    return patientData?.length || 0;
  }, [patientData]);

  const headings = [
    t('page-dashboard.table.heading.name'),
    t('page-dashboard.table.heading.personalName'),
    t('page-dashboard.table.heading.status'),
    t('page-dashboard.table.heading.familyId'),
  ];

  const showSearch =
    (patientData && patientData.length > 0) || search.length > 0;

  const emptyStateText = {
    title:
      search.length > 0
        ? t('page-dashboard.table.no-results.title', {
            searchQuery: search,
          })
        : t('page-dashboard.table.patient-empty-state.title'),
    message:
      search.length > 0
        ? t('page-dashboard.table.no-results.description')
        : t('page-dashboard.table.patient-empty-state.description'),
  };

  return (
    <Container maxWidth={false} disableGutters>
      {isLoading && <LoadingBackdrop />}
      <PanelHeader
        onAddNewPatientClick={handleAddNewPatientClick}
        title={t('page-dashboard.patient-list.title')}
        buttonText={t('page-dashboard.new-patient.buttonText')}
      />
      {showSearch && (
        <Search
          searchValue={search}
          setSearchValue={(searchValue: string) => {
            setSearch(searchValue);
          }}
          searchPlaceholder={t('search.placeholder-text')}
          searchLabel={t('search.search')}
        />
      )}
      {numberOfRows === 0 ? (
        <EmptyState
          title={emptyStateText.title}
          message={emptyStateText.message}
        />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {headings.map((heading, index) => (
                    <TableCell key={`heading-${index}`}>{heading}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {patientData?.map((patientRow, rowIdx: number) => (
                  <TableRow key={`row-${rowIdx}`}>
                    <TableCell>
                      <Link
                        to={`${ROUTES.patient}/${patientRow.patientId}`}
                        style={{
                          textDecoration: 'none',
                          color: theme.palette.common.brand.blue,
                        }}
                      >
                        {patientRow.patientName}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <PersonalNumberView
                        personalNumber={patientRow.patientPersonalNumber}
                      />
                    </TableCell>
                    <TableCell>
                      <PedigreeStatusView
                        pedigreeStatus={patientRow.pedigreeStatus}
                      />
                    </TableCell>
                    <TableCell>{patientRow.familyId}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalNumberOfRows}
            page={page}
            onPageChange={(_, nextPage) => {
              setPage(nextPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[]}
          />
        </>
      )}
    </Container>
  );
}

export default PatientListPanel;
