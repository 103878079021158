import {
  faCheck,
  faCheckCircle,
  faPen,
  faTrashCan,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DiagnosisHeaderProps {
  title: string;
  subtitle?: string;
  isConfirmed: boolean;
  showDeleteButton: boolean;
  showConfirmButton: boolean;
  showEditButton: boolean;
  showActionButtons: boolean;
  onDeleteDiagnosisClick: () => void;
  onEditDiagnosisClick: () => void;
  onConfirmDiagnosisClick: () => void;
}

export const DiagnosisHeader = ({
  title,
  subtitle,
  isConfirmed,
  showDeleteButton = false,
  showConfirmButton = false,
  showEditButton = false,
  showActionButtons = false,
  onDeleteDiagnosisClick,
  onEditDiagnosisClick,
  onConfirmDiagnosisClick,
}: DiagnosisHeaderProps) => {
  const { t } = useTranslation(['diagnosisForm']);
  const theme = useTheme();

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5">{title}</Typography>
        {showActionButtons && (
          <Stack direction="row" gap={1}>
            {showDeleteButton && (
              <DiagnosisAction
                title={t('tooltip-delete.title')}
                icon={faTrashCan}
                onClick={onDeleteDiagnosisClick}
              />
            )}
            {showEditButton && (
              <DiagnosisAction
                title={t('tooltip-edit.title')}
                icon={faPen}
                onClick={onEditDiagnosisClick}
              />
            )}
            {showConfirmButton && (
              <DiagnosisAction
                title={t('tooltip-confirm.title')}
                icon={faCheck}
                onClick={onConfirmDiagnosisClick}
              />
            )}
          </Stack>
        )}
      </Stack>
      {subtitle && (
        <Stack direction="row" alignItems="center" gap={0.5}>
          {isConfirmed && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="#48A136"
              fontSize={12}
            />
          )}
          <Typography
            variant="caption"
            color={
              isConfirmed
                ? '#48A136'
                : theme.palette.common.opacity.darkBlue[70]
            }
          >
            {subtitle}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

interface DiagnosisActionProps {
  title: string;
  icon: IconDefinition;
  onClick: () => void;
}

const DiagnosisAction = (props: DiagnosisActionProps) => {
  const theme = useTheme();
  const { onClick, title, icon } = props;

  return (
    <Tooltip title={title} arrow>
      <IconButton
        size="small"
        onClick={onClick}
        sx={{
          color: theme.palette.common.brand.darkBlue,
          width: 24,
          height: 24,
        }}
      >
        <FontAwesomeIcon icon={icon} />
      </IconButton>
    </Tooltip>
  );
};
