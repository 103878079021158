import { Stack } from '@mui/material';
import { ConsentManagementServiceProvider } from 'api/consentManagement/ConsentManagementServiceProvider';
import { StaffPatientServiceProvider } from 'api/patient/staff-patient-service';
import PdfServiceProvider from 'api/pdf/PdfServiceProvider';
import { StaffPedigreeCaseServiceProvider } from 'api/pedigreeCase/staff-pedigree-case-service';
import { UserServiceProvider } from 'api/user/user-service';
import i18n from 'assets/localization/i18n/iPedigreeI18nConfig';
import TopNav from 'common/layouts/TopNav';
import { withStaffAuthProvider } from 'features/auth/staff/StaffAuthProvider';
import { useStaffAuth } from 'iPedigree/common';
import DashboardPage from 'iPedigree/pages/DashboardPage';
import NewPatientPage from 'iPedigree/pages/NewPatientPage';
import SettingsPage from 'iPedigree/pages/SettingsPage';
import { I18nextProvider } from 'react-i18next';
import { Outlet, Route, Routes } from 'react-router-dom';
import { ROUTES } from '.';
import PatientPageRouter from './PatientPageRouter';

const Layout = () => {
  return (
    <Stack direction="column" sx={{ minHeight: '100vh' }}>
      <TopNav containerMaxWidth="xl" settingsPath={ROUTES.settings} />
      <Outlet />
    </Stack>
  );
};

const AuthenticatedRoutes = () => {
  const { accessToken, logout } = useStaffAuth();

  return (
    <I18nextProvider i18n={i18n}>
      <StaffPedigreeCaseServiceProvider
        accessToken={accessToken}
        logout={logout}
      >
        <UserServiceProvider accessToken={accessToken} logout={logout}>
          <StaffPatientServiceProvider
            accessToken={accessToken}
            logout={logout}
          >
            <ConsentManagementServiceProvider
              accessToken={accessToken}
              logout={logout}
            >
              <PdfServiceProvider accessToken={accessToken}>
                <Stack direction="column" sx={{ minHeight: '100vh' }}>
                  <Routes>
                    <Route element={<Layout />}>
                      <Route index element={<DashboardPage />} />
                      <Route
                        path={ROUTES.newPatient}
                        element={<NewPatientPage />}
                      />
                      <Route
                        path={ROUTES.patient + '/:patientId/*'}
                        element={<PatientPageRouter />}
                      />
                      <Route
                        path={ROUTES.settings}
                        element={<SettingsPage />}
                      />
                    </Route>
                  </Routes>
                </Stack>
              </PdfServiceProvider>
            </ConsentManagementServiceProvider>
          </StaffPatientServiceProvider>
        </UserServiceProvider>
      </StaffPedigreeCaseServiceProvider>
    </I18nextProvider>
  );
};

export default withStaffAuthProvider(AuthenticatedRoutes);
