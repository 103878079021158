import { Container, Stack } from '@mui/material';
import {
  PedigreeCaseStatus,
  useStaffPedigreeCaseService,
} from 'api/pedigreeCase/staff-pedigree-case-service';
import PatientOverviewPanel from 'common/features/pedigreeCase/patientOverview/PatientOverviewPanel';
import QuestionnaireOverviewController from 'common/features/pedigreeCase/questionnaire/controllers/QuestionnaireOverviewController';
import { LoadingBackdrop } from 'components/common';
import PedigreeCaseStatusPanel from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/PedigreeCaseStatusPanel';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

interface CaseTabProps {
  pedigreeCaseStatus?: PedigreeCaseStatus;
}

export const CaseTab = (props: CaseTabProps) => {
  const service = useStaffPedigreeCaseService();

  const { patientId } = useParams<{ patientId: string }>();

  const {
    data: pedigreeCase,
    isLoading,
    error,
  } = useQuery(['pedigreeCase', patientId], () =>
    service.getPedigreeCase(patientId || ''),
  );

  // Handle loading state
  if (isLoading) return <LoadingBackdrop />;

  // Handle error state
  if (error) return <div>Error...</div>;

  // Handle when data is not available
  if (!pedigreeCase) return <div>No pedigreeCase available</div>;

  if (!patientId)
    return (
      <div>
        Error: No pedigree case id provided. Please provide a valid pedigree
        case id.
      </div>
    );

  const showQuestionnarie =
    props.pedigreeCaseStatus === 'caseViewed' ||
    props.pedigreeCaseStatus === 'pedigreeReceived' ||
    props.pedigreeCaseStatus === 'pedigreeProvided';

  return (
    <Container sx={{ mt: 2 }}>
      <Stack mb={4} gap={3} direction={{ xs: 'column-reverse', md: 'row' }}>
        <Stack flex={8} rowGap={3}>
          <PedigreeCaseStatusPanel pedigreeCase={pedigreeCase} />
          {showQuestionnarie && (
            <QuestionnaireOverviewController pedigreeCaseId={pedigreeCase.id} />
          )}
        </Stack>
        <Stack flex={4} gap={3}>
          <PatientOverviewPanel patientId={patientId} />
        </Stack>
      </Stack>
    </Container>
  );
};
