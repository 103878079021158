import { Container } from '@mui/material';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PedigreeCaseStatusPanel from './PedigreeCaseStatusPanel';
import { PedigreeOverview } from './pedigreeOverview';

export const PedigreeTab = () => {
  const service = useStaffPedigreeCaseService();

  const { patientId } = useParams<{ patientId: string }>();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service.getPedigreeCase(patientId || ''),
  );

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (!pedigreeCase || !patientId) {
    return <div>No pedigreeCase available</div>;
  }

  const showPedigree =
    pedigreeCase.status === 'caseViewed' ||
    pedigreeCase.status === 'pedigreeReceived';

  if (!showPedigree) {
    return (
      <Container sx={{ mt: 2 }}>
        <PedigreeCaseStatusPanel pedigreeCase={pedigreeCase} />
      </Container>
    );
  }
  return (
    <PedigreeOverview patientId={patientId} pedigreeCaseId={pedigreeCase.id} />
  );
};
