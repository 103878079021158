import { Consent } from 'iPedigree/features/dashboard/table/ConsentsTable';

export const mockConsents: Consent[] = [
  {
    id: '1',
    isNew: true,
    relative: 'Anna Andersson',
    patient: 'Mona Andersson',
    patientUrl: '/patient/1/consent',
    patientPersonalNumber: '199201015678',
    status: 'accepted',
    date: '2024-04-18',
    actions: <></>,
  },
  {
    id: '2',
    isNew: true,
    relative: 'Nora Nordin',
    patient: 'Eleonora Nordin',
    patientUrl: '/patient/2/consent',
    patientPersonalNumber: '199201015678',
    status: 'accepted',
    date: '2024-04-18',
    actions: <></>,
  },
  {
    id: '3',
    isNew: true,
    relative: 'Helen Andersson',
    patient: 'Mona Andersson',
    patientPersonalNumber: '199201015678',
    patientUrl: '/patient/3/consent',
    status: 'accepted',
    date: '2024-04-18',
    actions: <></>,
  },
  {
    id: '4',
    isNew: false,
    relative: 'Ela Johnsson',
    patient: 'Michaela Johnsson',
    patientUrl: '/patient/4/consent',
    patientPersonalNumber: '199201015678',
    status: 'declined',
    date: '2024-04-17',
    actions: <></>,
  },
  {
    id: '5',
    isNew: false,
    relative: 'Lena Andersson',
    patient: 'Mona Andersson',
    patientUrl: '/patient/5/consent',
    patientPersonalNumber: '199201015678',
    status: 'pending',
    date: '2024-04-14',
    actions: <></>,
  },
  {
    id: '6',
    isNew: false,
    relative: 'Isak Smith',
    patient: 'Emmelie Isaksson',
    patientUrl: '/patient/6/consent',
    patientPersonalNumber: '199201015678',
    status: 'pending',
    date: '2024-04-14',
    actions: <></>,
  },
];
