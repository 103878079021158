import { PersonalNumberValidationResponse, Sex } from 'api/types';
import { formatPersonalNumberInput } from 'features/common/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import AddPersonalNumberFormView from '../views/PersonalNumberFormView';

interface PersonalNumberFormControllerProps {
  setPersonalNumber: (personalNumber: string) => void;
  setIsLoading?: (isLoading: boolean) => void;
  initialData?: string;
  onValidationSuccess?: (data: {
    name?: string;
    dateOfBirth: string;
    sex: Sex;
  }) => void;
  validatePersonalNumber: (
    personalNumber: string,
  ) => Promise<PersonalNumberValidationResponse>;
}

function PersonalNumberFormController(
  props: PersonalNumberFormControllerProps,
) {
  const [personalNumber, setPersonalNumber] = React.useState('');
  const [isValidated, setIsValidated] = React.useState(false);
  const [personalNumberErrorMessage, setPersonalNumberErrorMessage] =
    React.useState('');
  const { t } = useTranslation('all');

  React.useEffect(() => {
    if (props.initialData) {
      setPersonalNumber(props.initialData);
      setIsValidated(true);
    }
  }, [props.initialData]);

  const handlePersonalNumberChange = (personalNumber: string) => {
    personalNumber = formatPersonalNumberInput(personalNumber);
    setPersonalNumber(personalNumber);
    setPersonalNumberErrorMessage('');
  };

  const validatePersonalNumber = useMutation(props.validatePersonalNumber, {
    onSuccess: (data) => {
      if (data.validPersonalNumber) {
        setIsValidated(true);
        props.setPersonalNumber(personalNumber);
        props.onValidationSuccess?.({
          name: data.name,
          dateOfBirth: data.dateOfBirth,
          sex: data.sex,
        });
      } else {
        setPersonalNumberErrorMessage(
          t(
            'consent-management.personal-number-validation.errors.invalid-personal-number',
          ),
        );
      }
    },
    onSettled: () => props.setIsLoading && props.setIsLoading(false),
  });

  const handleProceedClick = () => {
    if (!personalNumber.length) {
      setPersonalNumberErrorMessage(t('error-message.required'));
      return;
    }
    if (personalNumber.length !== 12) {
      setPersonalNumberErrorMessage(
        t(
          'consent-management.personal-number-validation.errors.invalid-length',
        ),
      );
      return;
    }
    props.setIsLoading && props.setIsLoading(true);
    validatePersonalNumber.mutate(personalNumber);
  };

  return (
    <AddPersonalNumberFormView
      personalNumber={personalNumber}
      setPersonalNumber={handlePersonalNumberChange}
      personalNumberError={personalNumberErrorMessage}
      personalNumberValid={isValidated}
      handleProceedClick={handleProceedClick}
      isValidating={validatePersonalNumber.isLoading}
      disabled={isValidated}
    />
  );
}

export default PersonalNumberFormController;
