import { useTheme } from '@mui/material';
import { PedigreeCase } from 'api/pedigreeCase/staff-pedigree-case-service';
import { formatPersonalNumber } from 'common/utils';
import { Row } from 'myfamilytree/components/tables/BasicTable';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const { DateTime } = require('luxon');

interface UseCaseInfoTableProps {
  items: PedigreeCase[];
  tabSection: string;
}

export const useCaseInfoTable = (props: UseCaseInfoTableProps) => {
  const { items, tabSection } = props || {};
  const { t, i18n } = useTranslation(['myFamilyTree']);
  const theme = useTheme();
  const navigate = useNavigate();

  const tabSectionIsUpdate = tabSection === 'updates';

  let headings = [
    t('my-family-tree-management.page-dashboard.table.heading.name'),
    t('my-family-tree-management.page-dashboard.table.heading.personalName'),
    t('my-family-tree-management.page-dashboard.table.heading.purpose'),
    t('my-family-tree-management.page-dashboard.table.heading.status'),
  ];

  const hasNewStatusColumn = items?.some(({ viewed }) => viewed === false);

  if (tabSectionIsUpdate) {
    headings.push(
      t('my-family-tree-management.page-dashboard.table.heading.datum'),
    );

    if (!!hasNewStatusColumn) {
      headings.unshift('');
    }
  }

  const rows = items?.map(
    ({ patientId, status, receivedAt, patientDetails, purpose, viewed }) => {
      const { name, personalNumber } = patientDetails || {};

      const cellPropsName = {
        sx: {
          color: 'common.brand.blue',
          cursor: 'pointer',
        },
      };

      const cellPropsPurpose = {
        sx: {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '25%',
          maxWidth: '276px',
        },
      };

      const newStatusCell = {
        cell: (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 54,
              height: 32,
              backgroundColor: theme.palette.common.brand.lightGreen,
              borderRadius: 50,
            }}
          >
            {t(
              'my-family-tree-management.page-dashboard.table.cell.status.new',
            )}
          </span>
        ),
      };

      const action = () => navigate(`/patient/${patientId}`);

      const statusLabelExists = i18n.exists(
        `myFamilyTree:my-family-tree-management.page-dashboard.table.cell.status.${status}`,
      );

      let rows: Row = [
        { cell: name, action, cellProps: cellPropsName },
        { cell: formatPersonalNumber(personalNumber) },
        {
          cell: purpose?.map((item) => item.localizedName).join(', '),
          cellProps: cellPropsPurpose,
        },
        {
          cell: statusLabelExists
            ? t(
                `my-family-tree-management.page-dashboard.table.cell.status.${status}`,
              )
            : status,
        },
      ];

      if (tabSectionIsUpdate) {
        rows.push({
          cell: receivedAt ? convertDate(receivedAt) : '',
        });

        if (!!hasNewStatusColumn) {
          viewed === false
            ? rows.unshift({ ...newStatusCell })
            : rows.unshift({ cell: '' });
        }
      }

      return rows;
    },
  );

  return {
    rows,
    headings,
  };
};

const convertDate = (isoDate: string) => {
  let date = DateTime.fromISO(isoDate);
  return date.toFormat('dd.MM.yyyy');
};
