import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  PasswordValidation,
  SatisfiedPasswordCriteria,
} from './hooks/usePasswordStrength';

interface PasswordStrengthProps {
  password: string;
  passwordValidation: PasswordValidation;
}

export const PasswordStrength = (props: PasswordStrengthProps) => {
  const { passwordValidation, password } = props;
  const { t } = useTranslation('passwordSettings');
  const theme = useTheme();

  const colors = useMemo(() => {
    const COLORS = {
      neutral: theme.palette.common.neutral[200],
      weak: theme.palette.error.main,
      average: theme.palette.warning.main,
      strong: '#48A136',
    };
    return COLORS;
  }, [
    theme.palette.common.neutral,
    theme.palette.error.main,
    theme.palette.warning.main,
  ]);

  const progressBar = useMemo(() => {
    const { score } = passwordValidation;
    const COLORS = colors;

    const bars = Array(4).fill(COLORS.neutral);

    let fillColor = COLORS.neutral;
    let title = 'create-password';

    switch (score) {
      case 0:
      case 1:
        if (password) {
          fillColor = COLORS.weak;
          title = 'weak-password';
        }
        break;
      case 2:
        fillColor = COLORS.average;
        title = 'average-password';
        break;
      case 3:
      case 4:
        fillColor = COLORS.strong;
        title = score === 3 ? 'strong-password' : 'very-strong-password';
        break;
      default:
        break;
    }

    const barsToFill = password && score === 0 ? 1 : Math.min(score, 4);

    return {
      colors: bars.fill(fillColor, 0, barsToFill),
      title: t(title),
    };
  }, [colors, password, passwordValidation, t]);

  return (
    <Stack gap={3}>
      <Stack>
        <Typography variant="h6">{progressBar.title}</Typography>
        <Stack direction="row" gap={'12px'} mt={1}>
          {progressBar.colors.map((color: any, index) => (
            <Box
              key={index}
              width={95}
              height={4}
              borderRadius={4}
              sx={{ backgroundColor: color }}
            ></Box>
          ))}
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography variant="body2">
          {t('policy-password-recommendations.title')}:
        </Typography>
        {recommendationsLabels.map((recommendation, index) => (
          <Stack direction="row" gap={1} alignItems="center" key={index}>
            {passwordValidation.satisfiedPasswordCriteria.includes(
              recommendation.key,
            ) ? (
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="#48A136"
                fontSize={12}
              />
            ) : (
              <Box
                width={4}
                height={4}
                borderRadius={50}
                sx={{
                  backgroundColor: '#233749',
                }}
              ></Box>
            )}
            <Typography variant="body2">{t(recommendation.label)}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const recommendationsLabels: {
  key: SatisfiedPasswordCriteria;
  label: string;
}[] = [
  {
    key: 'minLength',
    label: 'policy-password-recommendations.length',
  },
  {
    key: 'minUperLowercase',
    label: 'policy-password-recommendations.uppercase-and-lowercase',
  },
  {
    key: 'minNumbers',
    label: 'policy-password-recommendations.number',
  },
  {
    key: 'minSymbols',
    label: 'policy-password-recommendations.special',
  },
  {
    key: 'notGuessable',
    label: 'policy-password-recommendations.guessable',
  },
];
