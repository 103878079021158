import { Container, Stack } from '@mui/material';
import { UpdateRelativeRepresentative } from 'api/consentManagement/consent-management-service';
import { useConsentManagementService } from 'api/consentManagement/ConsentManagementServiceProvider';
import { useStaffPatientService } from 'api/patient/staff-patient-service';
import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { useNotificationContext } from 'common/layouts/notification/NotificationProvider';
import LoadingBackdrop from 'components/common/LoadingBackdrop';
import { PatientData } from 'iPedigree/features/patient/common/PatientForm';
import { ConsentRequestReview } from 'iPedigree/features/patient/consent/ConsentRequestReview';
import ConsentSentConfirmation from 'iPedigree/features/patient/consent/ConsentSentConfirmation';
import { EditRelativeDeceasedForm } from 'iPedigree/features/patient/consent/editRelativeDeceased/EditRelativeDeceasedForm';
import { RelativeData } from 'iPedigree/features/patient/consent/editRelativeDeceased/RelativeDeceasedForm';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export interface RelativeDeceasedFormData {
  patientData: PatientData;
  relativeData: RelativeData;
  consentFor: string;
}

export const EditRelativeDeceasedPage = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const service = useStaffPedigreeCaseService();
  const consentService = useConsentManagementService();
  const patientService = useStaffPatientService();
  const { setNotification } = useNotificationContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation('consent');

  const [pageState, setPageState] = useState<
    'form' | 'review' | 'confirmation'
  >('form');

  const consent = location.state?.consent;

  const [consentId, setConsentId] = useState<string | null>(null);
  const [relativeDeceasedFormData, setRelativeDeceasedFormData] =
    useState<RelativeDeceasedFormData | null>(null);

  const relative = useMemo(() => {
    const { relativeData } = relativeDeceasedFormData || {};
    return [
      {
        name: relativeData?.name || '',
        relationshipToPatient: relativeData?.relationshipToPatient || '',
      },
    ];
  }, [relativeDeceasedFormData]);

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => service.getPedigreeCase(patientId || ''),
    {
      enabled: relativeDeceasedFormData === null,
      onSuccess: ({ patientDetails }) => {
        setConsentId(consent?.consentId);
        setRelativeDeceasedFormData({
          patientData: {
            name: patientDetails?.name || '',
            personalNumber: patientDetails?.personalNumber || '',
            email: patientDetails?.email || '',
            phoneNumber: patientDetails?.phoneNumber || '',
          },
          consentFor: consent?.consentFor || '',
          relativeData: {
            name: consent.relative,
            relationshipToPatient: consent.relation,
            personalNumber: consent?.personalNumber,
            proxySignerName: consent?.proxySignerName,
            proxySignerToConsenteeRelationship:
              consent.proxySignerToConsenteeRelationship,
            proxySignerPersonalNumber: consent.proxySignerPersonalNumber,
            specificProxySigner: !!consent?.proxySignerName,
          },
        });
      },
    },
  );

  const handleContinueClick = (data: RelativeDeceasedFormData) => {
    setRelativeDeceasedFormData(data);
    setPageState('review');
  };

  const updateRelativeRepresentativeMutation = useMutation({
    mutationFn: async (data: UpdateRelativeRepresentative) => {
      const { patientData } = relativeDeceasedFormData || {};

      await patientService.updatePatientDetails(patientId || '', {
        phoneNumber: patientData?.phoneNumber,
        email: patientData?.email,
      });

      return consentService.updateRelativeRepresentative(consentId || '', data);
    },
    onSuccess: () => {
      setPageState('confirmation');
    },
    onError: () => {
      setNotification({
        message: t('edit-relative-deceased-form.error-message'),
        type: 'error',
      });
    },
  });

  const handleOnSubmitClick = () => {
    const { relativeData } = relativeDeceasedFormData || {};
    if (!relativeDeceasedFormData) {
      return;
    }

    const {
      specificProxySigner,
      proxySignerName,
      proxySignerPersonalNumber,
      proxySignerToConsenteeRelationship,
    } = relativeData || {};

    const payload = specificProxySigner
      ? {
          proxySignerName,
          proxySignerPersonalNumber,
          proxySignerToConsenteeRelationship,
        }
      : {
          proxySignerName: null,
          proxySignerPersonalNumber: null,
          proxySignerToConsenteeRelationship: null,
        };

    return updateRelativeRepresentativeMutation.mutate(payload);
  };

  useEffect(() => {
    if (!consent) {
      const newPathname = location.pathname.replace(
        '/consents/edit-relative-deceased',
        '',
      );
      return navigate(newPathname);
    }
  }, [consent, location.pathname, navigate]);

  if (!pedigreeCase) {
    return null;
  }

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (pageState === 'confirmation') {
    const { patientData } = relativeDeceasedFormData || {};

    return (
      <Container>
        <Stack pt={3} pb={3}>
          <ConsentSentConfirmation
            patientName={patientData?.name || ''}
            relatives={relative}
          />
        </Stack>
      </Container>
    );
  }

  if (pageState === 'review') {
    const { patientData } = relativeDeceasedFormData || {};

    return (
      <Container>
        <Stack pt={3} pb={3}>
          <ConsentRequestReview
            patientName={patientData?.name || ''}
            patientPersonalNumber={patientData?.personalNumber || ''}
            relatives={relative || []}
            onContinueClick={handleOnSubmitClick}
            onBackButtonClick={() => setPageState('form')}
          />
        </Stack>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 3 }}>
      <EditRelativeDeceasedForm
        initialFormValues={relativeDeceasedFormData}
        onContinueClick={handleContinueClick}
      />
    </Container>
  );
};
