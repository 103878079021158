import { useTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/system';
import { usePedigreeLayout } from 'common/features/pedigree/common/hooks/usePedigreeLayout';
import usePedigreeNodeRelationshipLabels from 'common/features/pedigree/common/hooks/usePedigreeNodeRelationshipLabels';
import { PedigreeNode } from 'common/features/pedigree/common/types';
import { PartnerConnections } from 'common/features/pedigree/common/views/pedigreeRenderer/PartnerConnections';
import PedigreeEdges from 'common/features/pedigree/common/views/pedigreeRenderer/PedigreeEdges';
import { LoadingBackdrop } from 'components/common';
import { Pedigree } from 'myfamilytree/api/myFamilyTreeSubmission/my-family-tree-submission-service';
import React, { useState } from 'react';
import { Html } from 'react-konva-utils';
import { usePedigreeMutation } from '../hooks/usePedigreeMutation';
import usePedigreeResponsiveActions from '../hooks/usePedigreeResponsiveActions';
import PedigreeControlButtonsView from '../views/PedigreeControlButtonsView';
import PedigreeNodeController from './PedigreeNodeController';
import ResponsiveStage from './ResponsiveStage';

interface PedigreeStageProps {
  pedigree: Pedigree;
}

const nodeSize = {
  width: 160,
  height: 152,
};

const PedigreeStage = (props: PedigreeStageProps) => {
  const theme = useTheme();
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);

  const {
    drawer,
    dialogs,
    snackBars,
    isLoading,
    allowNodeClick,
    showNodeAction,
    openDrawer,
    handleNodeClick,
    handleNodeMenuOptionClick,
  } = usePedigreeMutation({
    pedigree: props.pedigree,
    setSelectedNodes,
    selectedNodes,
  });

  const { getLocalizedLabelOf, getLabelKeyOf } =
    usePedigreeNodeRelationshipLabels(props.pedigree.nodes);

  const { layout, layoutSize } = usePedigreeLayout({
    pedigreeNodes: props.pedigree.nodes,
    pedigreeType: 'patient',
  });

  const stageRef = React.useRef<any>(null);

  const { handleZoomIn, handleZoomOut, handleZoomReset } =
    usePedigreeResponsiveActions({
      nodeSize,
      stageRef,
    });

  const onNodeClick = (
    isSelected: boolean,
    pedigreeNode: PedigreeNode,
    relationshipLabel: string,
  ) => {
    handleNodeClick(pedigreeNode, relationshipLabel);
    setSelectedNodes((prevSelectedNodes) =>
      isSelected
        ? [...prevSelectedNodes, pedigreeNode.id]
        : prevSelectedNodes.filter((id) => id !== pedigreeNode.id),
    );
  };

  const drawerWidth = drawer.props.children.props.sx.width;

  return (
    <>
      <PedigreeControlButtonsView
        direction="column"
        right={drawer.props.open ? drawerWidth + 16 : 16}
        handleZoomIn={handleZoomIn}
        handleZoomOut={handleZoomOut}
        handleZoomReset={handleZoomReset}
        onDrawerOpen={openDrawer}
      />
      <ResponsiveStage stageRef={stageRef} layoutSize={layoutSize}>
        {props.pedigree.nodes.map((pedigreeNode) => {
          const { x, y } = layout?.nodes[pedigreeNode.id]?.pos || {
            x: 0,
            y: 0,
          };
          const relationshipKey = getLabelKeyOf(pedigreeNode.id);
          const showRelationshipLabel = relationshipKey !== 'relative';
          const relationshipLabel = getLocalizedLabelOf(pedigreeNode.id);
          return (
            <Html
              groupProps={{
                x: x,
                y: y,
                offsetX: nodeSize.width / 2,
                offsetY: nodeSize.height / 2,
              }}
              key={pedigreeNode.id}
            >
              <ThemeProvider theme={theme}>
                <PedigreeNodeController
                  isIndex={pedigreeNode.isIndex}
                  sex={pedigreeNode.sex}
                  hasDiagnoseHistory={pedigreeNode.hasDiagnoseHistory || false}
                  relativeRelationLabel={
                    showRelationshipLabel ? relationshipLabel : ''
                  }
                  nodeSize={nodeSize}
                  name={pedigreeNode.name}
                  diagnoses={pedigreeNode.diagnoses || []}
                  deceased={pedigreeNode.deceased || false}
                  isSelected={selectedNodes.includes(pedigreeNode.id)}
                  allowNodeClick={allowNodeClick}
                  onNodeClick={(isSelected) =>
                    onNodeClick(isSelected, pedigreeNode, relationshipLabel)
                  }
                  {...(showNodeAction
                    ? {
                        handleMemberMenuOptionClick: (value) => {
                          handleNodeMenuOptionClick(value, pedigreeNode.id);
                        },
                      }
                    : undefined)}
                />
              </ThemeProvider>
            </Html>
          );
        })}

        <PedigreeEdges
          links={layout?.links}
          strokeOptions={{
            color: theme.palette.common.brand.darkBlue70,
            width: 1,
          }}
        />
        <PartnerConnections
          pedigreeLayoutNodes={layout?.nodes}
          color={theme.palette.common.brand.darkBlue}
        />
      </ResponsiveStage>
      {drawer}
      {dialogs}
      {snackBars}
      <LoadingBackdrop open={isLoading} />
    </>
  );
};

export default PedigreeStage;
