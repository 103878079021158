import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import SendRequest from 'common/features/pedigreeCase/sendPedigreeRequestReminder/SendRequest';
import { LoadingBackdrop } from 'components/common';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const SendRequestPage = () => {
  const { patientId } = useParams();
  const pedigreeCaseService = useStaffPedigreeCaseService();

  const { data: pedigreeCase, isLoading } = useQuery(
    ['pedigreeCase', patientId],
    () => pedigreeCaseService.getPedigreeCase(patientId || ''),
    {
      enabled: Boolean(patientId),
    },
  );

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (!patientId || !pedigreeCase) {
    return null;
  }

  return (
    <SendRequest patientId={patientId} pedigreeCaseId={pedigreeCase?.id} />
  );
};

export default SendRequestPage;
