import { Container, Stack } from '@mui/material';
import PatientOverviewPanel from 'common/features/pedigreeCase/patientOverview/PatientOverviewPanel';
import PedigreeOverviewPanel from 'iPedigree/features/patient/pedigree/PedigreeOverviewPanel';
import { useParams } from 'react-router-dom';

function PatientOverviewPage() {
  const { patientId } = useParams<{
    patientId: string;
  }>();

  if (!patientId) {
    console.error('No patient id provided. Please provide a valid patient id.');
    return (
      <div>
        <h1>
          Error: No patient id provided. Please provide a valid patient id.
        </h1>
      </div>
    );
  }

  return (
    <Container>
      <Stack direction="row" spacing={3} mt={3} alignItems="flex-start">
        <PedigreeOverviewPanel
          patientId={patientId}
          currentTab="patient-overview"
        />
        <PatientOverviewPanel patientId={patientId} />
      </Stack>
    </Container>
  );
}

export default PatientOverviewPage;
