import { useStaffPedigreeCaseService } from 'api/pedigreeCase/staff-pedigree-case-service';
import { LoadingBackdrop } from 'components/common';
import { useTabNavigation } from 'components/tabNavigation/hooks/useTabNavigation';
import { TabSectionPanel } from 'components/tabNavigation/views/TabSectionPanel';
import { CaseTab } from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/CaseTab';
import {
  PedigreeCasePageNavBar,
  TabValue,
} from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/PedigreeCasePageNavBar';
import { PedigreeTab } from 'myfamilytree/features/myFamilyTreeManagement/pedigreeCase/PedigreeTab';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

const tabNames: TabValue[] = ['patient', 'pedigree'];

export const PedigreeCasePage = () => {
  const { currentTab, handleChange } = useTabNavigation<TabValue>(
    'patient',
    tabNames,
  );
  const navigate = useNavigate();

  const service = useStaffPedigreeCaseService();

  const { patientId } = useParams<{ patientId: string }>();

  const { data, isLoading } = useQuery(['pedigreeCase', patientId], () =>
    service.getPedigreeCase(patientId || ''),
  );

  if (isLoading) return <LoadingBackdrop />;

  if (!data) return null;

  return (
    <>
      <PedigreeCasePageNavBar
        currentTab={currentTab}
        handleChange={handleChange}
        onBackButtonClick={() => {
          navigate('/?tab=all-patient');
        }}
        patientName={data?.patientDetails?.name as string}
      />
      <TabSectionPanel currentValue={currentTab} value="patient">
        <CaseTab pedigreeCaseStatus={data?.status} />
      </TabSectionPanel>
      <TabSectionPanel currentValue={currentTab} value="pedigree">
        <PedigreeTab />
      </TabSectionPanel>
    </>
  );
};
