import { Button } from '@mui/material';
import { useNotificationContext } from 'common/layouts/notification/NotificationProvider';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  CanChangeNodeSexResponse,
  NodeSexNotChangeableReason,
  Pedigree,
  PedigreeNodeSex,
} from '../../types';
import { findNodeById } from '../../utils/helpers';
import { DialogContentType } from '../../views/Dialog';

export type ChangeNodeSex =
  | 'changeSexToMale'
  | 'changeSexToFemale'
  | 'changeSexToUnknown';

interface UseChangeNodeSex {
  pedigree?: Pedigree | null;
  setDialogContent: (content: DialogContentType) => void;
  canChangeSex: (
    pedigreeId: string,
    nodeId: number,
    queryParams: {
      newSex: PedigreeNodeSex;
    },
  ) => Promise<CanChangeNodeSexResponse>;
  updateNodeSex: (
    pedigreeId: string,
    nodeId: number,
    sex: PedigreeNodeSex,
  ) => Promise<Pedigree>;
  setPedigree: (Pedigree: Pedigree | null) => void;
}

export const useChangeNodeSex = (props: UseChangeNodeSex) => {
  const {
    pedigree,
    setDialogContent,
    setPedigree,
    updateNodeSex,
    canChangeSex,
  } = props;

  const { t } = useTranslation(['pedigree', 'common']);
  const { setNotification } = useNotificationContext();

  const pedigreeId = pedigree?.id;

  const closeDialog = () => {
    setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const updateNodeSexMutation = useMutation(
    (params: { pedigreeNodeId: string; sex: PedigreeNodeSex }) =>
      updateNodeSex(
        pedigreeId || '',
        parseInt(params.pedigreeNodeId),
        params.sex,
      ),
    {
      onSuccess: (data) => {
        setPedigree(data);
        setNotification({
          message: t('node-member-snackbar-message.sex-changed.success'),
          type: 'toast',
        });
      },
    },
  );

  const canUpdateNodeSexMutation = useMutation(
    (params: { pedigreeNodeId: string; sex: PedigreeNodeSex }) =>
      canChangeSex(pedigreeId || '', parseInt(params.pedigreeNodeId), {
        newSex: params.sex,
      }),
    {
      onSuccess: (data, params) => {
        if (data.canChange) {
          return changeNodeSex(params);
        }
        return openNotChangeableSexDialog(data.reason);
      },
      onError: () => {
        return setNotification({
          message: t('common:message.error'),
          type: 'error',
        });
      },
    },
  );

  const changeNodeSex = ({
    pedigreeNodeId,
    sex,
  }: {
    pedigreeNodeId: string;
    sex: PedigreeNodeSex;
  }) => {
    if (!pedigree) return;
    const pedigreeNode = findNodeById(pedigree.nodes, pedigreeNodeId);

    const isIdenticalTwin = pedigreeNode?.twinRelation?.type === 'identical';
    if (isIdenticalTwin) {
      return openChangeSexTwinConfirmationDialog(pedigreeNodeId, sex);
    }

    return updateNodeSexMutation.mutate({ pedigreeNodeId, sex });
  };

  const updateSexTwins = (pedigreeNodeId: string, sex: PedigreeNodeSex) => {
    closeDialog();
    return updateNodeSexMutation.mutate({ pedigreeNodeId, sex });
  };

  const openNotChangeableSexDialog = (reason: NodeSexNotChangeableReason) => {
    setDialogContent({
      open: true,
      title: t('node-member-dialog.change-sex.not-allowed.title'),
      content: getDialogContent(t, reason),
      actions: [
        <Button
          key="close-not-chanhe-node-sex-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-member-dialog.change-sex.not-allowed.button.confirm')}
        </Button>,
      ],
    });
  };

  const openChangeSexTwinConfirmationDialog = (
    pedigreeNodeId: string,
    sex: PedigreeNodeSex,
  ) => {
    setDialogContent({
      open: true,
      title: t('node-member-dialog.change-sex-of-twin.title'),
      content: t('node-member-dialog.change-sex-of-twin.content'),
      actions: [
        <Button
          key="close-twin-sex-change-dialog"
          variant="outlined"
          onClick={closeDialog}
        >
          {t('node-member-dialog.change-sex-of-twin.button.no')}
        </Button>,
        <Button
          key="confirm-sex-change"
          variant="contained"
          onClick={() => updateSexTwins(pedigreeNodeId, sex)}
        >
          {t('node-member-dialog.change-sex-of-twin.button.yes')}
        </Button>,
      ],
    });
  };

  const handleChangingSex = (action: ChangeNodeSex, pedigreeNodeId: string) => {
    const NODE_SEX = {
      changeSexToMale: PedigreeNodeSex.MALE,
      changeSexToFemale: PedigreeNodeSex.FEMALE,
      changeSexToUnknown: PedigreeNodeSex.UNKNOWN,
    };
    const sex = NODE_SEX[action];

    if (!pedigree) return;
    const pedigreeNode = findNodeById(pedigree.nodes, pedigreeNodeId);

    if (pedigreeNode?.sex === sex) return;
    canUpdateNodeSexMutation.mutate({ pedigreeNodeId, sex });
  };

  return {
    handleChangingSex,
  };
};

const getDialogContent = (t: TFunction, reason: NodeSexNotChangeableReason) => {
  const CONTENT_MAP = {
    NODE_IS_INDEX: t('node-member-dialog.change-sex.not-allowed.content.index'),
    NODE_HAS_PARTNER: t(
      'node-member-dialog.change-sex.not-allowed.content.with-partner-or-child',
    ),
    NODE_IS_DIRECT_ASCENDANT_OF_INDEX: t(
      'node-member-dialog.change-sex.not-allowed.content.direct-ascendant',
    ),
    NODE_HAS_IDENTICAL_TWIN_WITH_PARTNER: t(
      'node-member-dialog.change-sex.not-allowed.content.identical-twin-with-partner',
    ),
    NODE_IS_IDENTICAL_TWIN_TO_INDEX: t(
      'node-member-dialog.change-sex.not-allowed.content.identical-twin-to-index',
    ),
    NODE_IS_IDENTICAL_TWIN_TO_DIRECT_ASCENDANT_OF_INDEX: t(
      'node-member-dialog.change-sex.not-allowed.content.identical-twin-to-direct-ascendant-of-index',
    ),
    SINGLE_PARENT_NODE_CANNOT_HAVE_UNKNOWN_SEX: t(
      'node-member-dialog.change-sex.not-allowed.content.with-partner-or-child',
    ),
  };
  return CONTENT_MAP[reason];
};
