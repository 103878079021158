import { Button } from '@mui/material';
import {
  DrawerFormConfirmationData,
  DrawerState,
} from 'common/features/pedigree';
import { useNotificationContext } from 'common/layouts/notification/NotificationProvider';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { SelectionParentParams } from '../../controllers/nodeSelectionForm/ParentSelectionController';
import {
  ParentsIds,
  Pedigree,
  SharedParentRelationshipType,
  UpdatePedigreeNodeParents,
} from '../../types';
import {
  findIndexNode,
  findNodeById,
  getAllAscendants,
} from '../../utils/helpers';
import { DialogContentType } from '../../views/Dialog';

export type ChangeParentsNodeParams = {
  action: 'changeParents';
  otherParentNodeId: string | null;
  sharedRelationshipType?: SharedParentRelationshipType;
};

interface useUpdateNodeParentsProps {
  pedigree?: Pedigree | null;
  setPedigree: (Pedigree: Pedigree | null) => void;
  setDialogContent: (content: DialogContentType) => void;
  setDrawerState: (info: DrawerState) => void;
  setSelectedNodeId: (pedigreeNodeId: string | null) => void;
  updatePedigreeNodeParents: UpdatePedigreeNodeParents;
}

export const useUpdateNodeParents = (props: useUpdateNodeParentsProps) => {
  const {
    pedigree,
    setDialogContent,
    setDrawerState,
    setSelectedNodeId,
    setPedigree,
  } = props;

  const { t } = useTranslation('pedigree');
  const { setNotification } = useNotificationContext();

  const updateParentsMutation = useMutation(
    (params: {
      pedigreeId: string;
      pedigreeNodeId: string;
      parentsIds: ParentsIds;
    }) =>
      props.updatePedigreeNodeParents(
        params.pedigreeId,
        params.pedigreeNodeId,
        params.parentsIds,
      ),
    {
      onSuccess: (data) => {
        setPedigree(data);
        setNotification({
          message: t('node-member-snackbar-message.add.success'),
          type: 'toast',
        });
      },
    },
  );

  const handleUpdateParentsNode = ({
    pedigreeNodeId,
    otherParentNodeId,
    sharedRelationshipType,
  }: SelectionParentParams) => {
    const pedigreeNode = pedigree?.nodes.find(
      (node) => node.id === pedigreeNodeId,
    );

    const fatherId =
      sharedRelationshipType === 'father'
        ? pedigreeNode?.fatherId
        : otherParentNodeId ?? undefined;

    const motherId =
      sharedRelationshipType === 'mother'
        ? pedigreeNode?.motherId
        : otherParentNodeId ?? undefined;

    updateParentsMutation.mutate({
      pedigreeId: pedigree?.id || '',
      pedigreeNodeId,
      parentsIds: {
        fatherId: fatherId ? parseInt(fatherId) : undefined,
        motherId: motherId ? parseInt(motherId) : undefined,
      },
    });
    return;
  };

  const closeDialog = () => {
    setDialogContent({
      open: false,
      title: '',
      content: '',
      actions: [],
    });
  };

  const openDrawerToSelectionParents = (
    action: 'changeParents',
    pedigreeNodeId: string,
  ) => {
    closeDialog();
    const state: DrawerState = {
      type: 'parentSelection',
      pedigreeNodeId,
      action,
      onClickConfirm: (data: DrawerFormConfirmationData) => {
        if (data.action === 'changeParents') {
          return handleUpdateParentsNode(data);
        }
      },
    };
    setDrawerState(state);
    setSelectedNodeId(pedigreeNodeId);
  };

  const openDialogToConfirmChangeParents = (pedigreeNodeId: string) => {
    props.setDialogContent({
      open: true,
      title: t('node-member-dialog.changed-parent.title'),
      content: t('node-member-dialog.changed-parent.content'),
      actions: [
        <Button
          key="close-changing-parents-dialog"
          variant="outlined"
          onClick={closeDialog}
        >
          {t('node-member-dialog.changed-parent.button.cancel')}
        </Button>,
        <Button
          key="continue-changing-parents-dialog"
          variant="contained"
          onClick={() => {
            openDrawerToSelectionParents('changeParents', pedigreeNodeId);
          }}
        >
          {t('node-member-dialog.changed-parent.button.confirm')}
        </Button>,
      ],
    });
  };

  const openDialogCannotChangeParents = () => {
    props.setDialogContent({
      open: true,
      title: t('node-member-dialog.cannot-change-parents.title'),
      content: t('node-member-dialog.cannot-change-parents.content'),
      actions: [
        <Button
          key="close-cannot-change-parents-dialog"
          variant="contained"
          onClick={closeDialog}
        >
          {t('node-member-dialog.cannot-change-parents.button.confirm')}
        </Button>,
      ],
    });
  };

  const handleChangingParents = (pedigreeNodeId: string) => {
    if (isAllowedToChangeParents(pedigreeNodeId)) {
      openDialogToConfirmChangeParents(pedigreeNodeId);
      return;
    }
    return openDialogCannotChangeParents();
  };

  const isAllowedToChangeParents = (pedigreeNodeId: string): boolean => {
    if (!pedigree?.nodes) return false;

    const pedigreeNode = findNodeById(pedigree.nodes, pedigreeNodeId);
    const pedigreeIndexNode = findIndexNode(pedigree.nodes);
    if (!pedigreeIndexNode) return false;

    const isIndexNode = Boolean(pedigreeNode?.isIndex);
    if (isIndexNode) return false;

    const ascendants = getAllAscendants(pedigreeIndexNode, pedigree.nodes);

    const isIndexAscendant = ascendants.some(
      (node) => node.id === pedigreeNodeId,
    );
    if (isIndexAscendant) return false;

    return true;
  };

  return {
    handleChangingParents,
  };
};
