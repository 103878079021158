import { FormProps } from '@rjsf/core';
import Form from '@rjsf/mui';
import {
  getDefaultFormState,
  RegistryFieldsType,
  RegistryWidgetsType,
  SubmitButtonProps,
  TemplatesType,
} from '@rjsf/utils';
import validator from '@rjsf/validator-ajv8';
import {
  createContext,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CalculatedBmiField } from './fields/CalculatedBmiField';
import { NumberField } from './fields/NumberField';
import { FieldErrorTemplate } from './templates/FieldErrorTemplate';
import { FieldTemplate } from './templates/FieldTemplate';
import { ListFieldTemplate } from './templates/ListFieldTemplate';
import { ObjectFieldTemplate } from './templates/ObjectFieldTemplate';
import { SubmitButtonTemplate } from './templates/SubmitButtonTemplate';
import useCustomTransformErrors from './useCustomTransformErrors';
import { AutocompleteWidget } from './widgets/AutocompleteWidget';
import { CheckboxWidget } from './widgets/CheckboxWidget';
import { DateFieldWidget } from './widgets/DateFieldWidget';
import { DateWidget } from './widgets/DateWidget';
import { RadioWidget } from './widgets/RadioWidget';
import { ReadonlyWidget } from './widgets/ReadonlyWidget';
import { TextWidget } from './widgets/TextWidget';
import { TextareaWidget } from './widgets/TextareaWidget';

export const DynamicFormContext = createContext<{
  isSubmitError: boolean;
  setIsSubmitError: (value: boolean) => void;
  formState: any;
  updateFormState: boolean;
  setUpdateFormState: (value: boolean) => void;
  formRef: any | null;
}>({
  isSubmitError: false,
  setIsSubmitError: () => {},
  formState: {},
  updateFormState: false,
  setUpdateFormState: () => {},
  formRef: null,
});

interface DynamicFormProps extends Omit<FormProps, 'validator'> {
  fields?: RegistryFieldsType;
  widgets?: RegistryWidgetsType;
}

const submitButton = (props: SubmitButtonProps, isLoading: boolean) => {
  return <SubmitButtonTemplate {...props} loading={isLoading} />;
};

export const DynamicForm = forwardRef(function DynamicForm(
  props: DynamicFormProps,
  ref: any,
) {
  const formRef = useRef<any>(null);

  const [isSubmitError, setIsSubmitError] = useState<boolean>(false);
  const [formState, setFormState] = useState<any>(null);
  const [updateFormState, setUpdateFormState] = useState<boolean>(false);

  const customFields: RegistryFieldsType = {
    NumberField,
    CalculatedBmiField,
    ...props.fields,
  };

  const { transformErrors } = useCustomTransformErrors();

  const customWidgets: RegistryWidgetsType = {
    TextareaWidget,
    RadioWidget,
    AutocompleteWidget,
    TextWidget,
    DateWidget,
    DateFieldWidget,
    EmailWidget: TextWidget,
    ReadonlyWidget,
    CheckboxWidget,
    ...props.widgets,
  };

  const isLoading: boolean = props.disabled || false;

  const ButtonTemplates = {
    SubmitButton: (props: SubmitButtonProps) => submitButton(props, isLoading),
    AddButton: () => null,
    CopyButton: () => null,
    MoveDownButton: () => null,
    MoveUpButton: () => null,
    RemoveButton: () => null,
  };

  const customTemplates: Partial<TemplatesType> = {
    ArrayFieldTemplate: ListFieldTemplate,
    FieldErrorTemplate: FieldErrorTemplate,
    ObjectFieldTemplate,
    ButtonTemplates,
    FieldTemplate,
  };

  const onError = () => {
    setIsSubmitError(true);
  };

  useImperativeHandle(ref, () => ({
    ...formRef.current,
    setUpdateFormState,
    getDefaultFormState: () =>
      getDefaultFormState(
        validator,
        props.schema,
        props.formData,
        props.schema,
      ),
  }));

  const handleOnchange = (data: any) => {
    setFormState(data);
    props.onChange && props.onChange(data);
  };

  return (
    <DynamicFormContext.Provider
      value={{
        isSubmitError,
        setIsSubmitError,
        formState,
        formRef,
        updateFormState,
        setUpdateFormState,
      }}
    >
      <Form
        {...props}
        onChange={handleOnchange}
        showErrorList={false}
        onError={onError}
        focusOnFirstError={true}
        validator={validator}
        widgets={customWidgets}
        templates={customTemplates}
        fields={customFields}
        transformErrors={transformErrors}
        omitExtraData
        liveOmit
        noHtml5Validate
        ref={formRef}
      >
        {props.children}
      </Form>
    </DynamicFormContext.Provider>
  );
});
